import React, { useEffect, useState } from 'react';
import { defaultProviderType, ProviderType } from '../../../app/models/ProviderType';
import useProviderTypeService from '../../../app/services/hooks/useProviderTypeService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import ProviderTypeFormContainer from './ProviderTypeFormContainer';

interface Props {
    placeId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const ProviderTypeEdit = ({ placeId, onSaved, onCancel, onError }: Props) => {
    const {
        fetchingEditProviderType,
        editProviderType,
        fetchingUpdateProviderType,
        updateProviderType
    } = useProviderTypeService();

    const [providerType, setProviderType] = useState<ProviderType>(defaultProviderType);
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        editProviderType(placeId, {
            onSuccess: (response: ServiceResponse) => {
                setProviderType(response.data.provider_type);
            },
            onError: () => {
                if (onError) onError();
            }
        });
    };

    const update = () => {
        updateProviderType(placeId, providerType, {
            onSuccess: () => {
                if (onSaved) onSaved();
            },
            onError: () => {
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditProviderType ? (
        <LazyLoading height="300" />
    ) : (
        <ProviderTypeFormContainer
            fetching={fetchingUpdateProviderType}
            action={update}
            cancel={cancel}
            providerType={providerType}
            setProviderType={setProviderType}
            errorFields={errorFields}
        />
    );
};

export default ProviderTypeEdit;
