import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { PurchaseorderDataForm } from '../../../../app/models/PurchaseOrder';
import { Tax } from '../../../../app/models/Tax';


interface GeneralPurchaseOrderProps {
    purchaseOrderDataForm: PurchaseorderDataForm;
}

const PurchaseOrderShowForm = ({
    purchaseOrderDataForm,
}: GeneralPurchaseOrderProps) => {
   
    if (!purchaseOrderDataForm) return null;

    return (
        <>
        
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                            Sociedad
                    </label>
                    <input
                        name="society"
                        id="society"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.society}
                        disabled={true}
                    />
                </div>
            </div>
       
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Rut
                    </label>
                    <input
                        name="society_rut"
                        id="society_rut"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.society_rut}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">
                        Razón social
                    </label>
                    <input
                        name="society_business_name"
                        id="society_business_name"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.society_name}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                        Dirección
                    </label>
                    <input
                        name="society_address"
                        id="society_address"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.society_address}
                        disabled={true}
                    />
                </div>
            </div>
       
        <hr />
            <div className="mb-2 row">
                <div className="col-md-12">
                <div className="col-md-12">
                    <label className="col-form-label">
                        Proveedor
                    </label>
                    <input
                        name="provider"
                        id="provider"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider}
                        disabled={true}
                    />
                </div>
                </div>
            </div>
            
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Rut
                    </label>
                    <input
                        name="provider_rut"
                        id="provider_rut"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_rut}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">
                        Razón social
                    </label>
                    <input
                        name="provider_name"
                        id="provider_name"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_name}
                        disabled={true}
                    />
                </div>
            </div>
           
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                        Dirección
                    </label>
                    <input
                        name="provider_address"
                        id="provider_address"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_address}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Nombre contacto
                    </label>
                    <input
                        name="provider_name_contact"
                        id="provider_name_contact"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_name_contact}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">
                       Email contacto
                    </label>
                    <input
                        name="provider_contact_email"
                        id="provider_contact_email"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.provider_email_contact}
                        disabled={true}
                    />
                </div>
            </div>
          
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">
                       Teléfono
                    </label>
                    <div className="row">
                        {/* <div className="col-4">
                            <input
                                name="provider_phone_code_contact"
                                id="provider_phone_code_contact"
                                type="text"
                                className="form-control"
                                value={purchaseOrderDataForm.provider_phone_code_contact}
                                disabled={true}
                            />
                        </div> */}
                        <div className="col-8">
                            <input
                                name="provider_phone_contact"
                                id="provider_phone_contact"
                                type="text"
                                className="form-control"
                                value={purchaseOrderDataForm.provider_phone_contact}
                                disabled={true}
                            />
                        </div>
                    </div>
                   
                </div>
            </div>
            
            <hr />
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                       Forma de pago
                    </label>
                    <input
                        name="payment_type"
                        id="payment_type"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.payment_type}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">
                        Moneda
                    </label>
                    <input
                        name="currency"
                        id="currency"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.currency}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Condición de pago
                    </label>
                    <input
                        name="payment_condition"
                        id="payment_condition"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.payment_condition}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">
                        Cantidad de días
                    </label>
                    <input
                        name="days_count"
                        id="days_count"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.days_count}
                        disabled={true}
                    />
                </div>
            </div>
            
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">
                        Condicion de entrega
                    </label>
                    <input
                        name="incoterm"
                        id="incoterm"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.incoterm}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                <label className="col-form-label">Destino</label>
                    <input
                        name="destiny"
                        id="destiny"
                        type="text"
                        className="form-control"
                        value={purchaseOrderDataForm.destiny}
                        disabled={true}
                    />
                </div>
            </div>
        </>
    );
};

export default PurchaseOrderShowForm;
