import React from 'react';
import { IconProps } from '../types';

export const Carrier: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M17 0C16.4477 0 16 0.44772 16 1V19C16 19.5523 16.4477 20 17 20C17.5523 20 18 19.5523 18 19V8.7808L23.2425 7.47014C23.6877 7.35885 24 6.95887 24 6.5V1C24 0.44772 23.5523 0 23 0H17z"
                fill="#758CA3"
            />
            <path
                d="M7 11C9.20914 11 11 9.2091 11 7C11 4.79086 9.20914 3 7 3C4.79086 3 3 4.79086 3 7C3 9.2091 4.79086 11 7 11z"
                fill="#758CA3"
            />
            <path
                d="M1 20C0.447715 20 0 19.5523 0 19V17C0 14.2386 2.23858 12 5 12H9C11.7614 12 14 14.2386 14 17V19C14 19.5523 13.5523 20 13 20C7.15968 20 2.82301 20 1 20z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Carrier;
