import React, { useContext, useEffect, useState } from 'react';

import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';
import useIncotermService from '../../app/services/hooks/useIncotermService';
import { Incoterm, IncotermFilters } from '../../app/models/Incoterm';
import { useHistory } from 'react-router-dom';
import IncotermDataTable from './components/tables/IncotermDataTable';
import IncotermCreate from './components/IncotermCreate';
import IncotermEdit from './components/IncotermEdit';
import useNavigationPage from '../../hooks/useNavigationPage';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Incoterms',
        url: '/incoterms',
        isActive: true
    }
];

const Incoterms = () => {
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingGetIncoterms, getIncoterms, deleteIncoterm, activeIncoterm } =
        useIncotermService();
    const [incoterms, setIncoterms] = useState<Incoterm[]>([]);

    const [incotermIdEdit, setincotermIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [incotermFilter, setIncotermFilter] = useState<IncotermFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'asc'
    });

    useEffect(() => {
        reloadTable();
    }, [incotermFilter]);

    const getAllIncoterms = () => {
        getIncoterms(incotermFilter, {
            onSuccess: (response: ServiceResponse) => {
                setIncoterms(response.data.incoterms);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/')
                }
            }
        });
    };

    const showEdit = (incotermId: number) => {
        setShowingEdit(true);
        setincotermIdEdit(incotermId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setincotermIdEdit(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllIncoterms();
    };

    const destroy = (incotermId: number) => {
        const _text = 'Está a punto de eliminar la condicion de entrega#' + incotermId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando la condicion de entrega...');
                        deleteIncoterm(incotermId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La condicion de entrega no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };


    return (
        <>
            <Breadcrumbs pageSection="Condicion de entrega" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva condicion de entrega" />}
                />
                <IncotermDataTable
                    incoterms={incoterms}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetIncoterms}
                    filter={incotermFilter}
                    setFilter={setIncotermFilter}
                    totalRows={totalRows}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Condicion de entrega"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncotermCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreate();
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Condicion de entrega"
                    backdrop={true}
                    showFooter={false}
                >
                    <IncotermEdit
                        incotermId={incotermIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Incoterms;
