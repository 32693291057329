import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import {
    CheckingAccountDataForm,
    defaultCheckingAccountDataForm
} from '../../../app/models/CheckingAccount';
import useCheckingAccountService from '../../../app/services/hooks/useCheckingAccount';
import { Bank } from '../../../app/models/Bank';
import { BankAccountType } from '../../../app/models/BankAccountType';
import { Currency } from '../../../app/models/Currency';
import CheckingAccountForm from './forms/CheckingAccountForm';
import moment from 'moment';
import { BusinessName } from '../../../app/models/BusinessName';
import { BusinessEntity } from '../../../app/models/BusinessEntity';
import useBusinessNamesService from '../../../app/services/hooks/useBusinessService';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import useBusinessNameBankAccountService from '../../../app/services/hooks/useBusinessNameBankAccountService';
import { BusinessNameBankAccount } from '../../../app/models/BusinessNameBankAccount';
import { TransferTypes } from '../../../app/shared/enums';


interface Props {
    transferType: string;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CheckingAccountCreate = ({ transferType, onSaved, onCancel, onError }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [checkingAccount, setCheckingAccount] = useState<CheckingAccountDataForm>(
        {
            ...defaultCheckingAccountDataForm,
            transfer_type: transferType
        }
    );
    const [senderBusinessNames, setSenderBusinessNames] = useState<BusinessName[]>([]);
    const [senderBusinessEntities, setSenderBusinessEntities] = useState<BusinessEntity[]>([]);
    const [senderBankAccountReasons, setSenderBankAccountReasons] = useState<BankAccountReason[]>([]);
    const [senderBankAccounts, setSenderBankAccounts] = useState<BusinessNameBankAccount[]>([]);
    const [senderBanks, setSenderBanks] = useState<Bank[]>([]);
    const [senderBankAccountTypes, setSenderBankAccountTypes] = useState<BankAccountType[]>([]);
    
    const [receiverBusinessNames, setReceiverBusinessNames] = useState<BusinessName[]>([]);
    const [receiverBusinessEntities, setReceiverBusinessEntities] = useState<BusinessEntity[]>([]);
    const [receiverBankAccountReasons, setReceiverBankAccountReasons] = useState<BankAccountReason[]>([]);
    const [receiverBankAccounts, setReceiverBankAccounts] = useState<BusinessNameBankAccount[]>([]);
    const [receiverBanks, setReceiverBanks] = useState<Bank[]>([]);
    const [receiverBankAccountTypes, setReceiverBankAccountTypes] = useState<BankAccountType[]>([]);

    const [currencies, setCurrencies] = useState<Currency[]>([]);
    
    const senderBusinessEntitySelected = useRef<string | undefined>('');
    const senderBusinessNameIdSelected = useRef<number | undefined>(0);
    const senderBankAccountReasonIdSelected = useRef<number | undefined>(0);

    const receiverBusinessEntitySelected = useRef<string | undefined>('');
    const receiverBusinessNameIdSelected = useRef<number | undefined>(0);
    const receiverBankAccountReasonIdSelected = useRef<number | undefined>(0);
   
    const [errorFields, setErrorFields] = useState<any>();

    const {
        fetchingCreateCheckingAccount,
        fetchingStoreCheckingAccount,
        createCheckingAccount,
        storeCheckingAccount
    } = useCheckingAccountService();

    const {
        getBusinessNameList,
        fetchingGetBusinessNameList
    } = useBusinessNamesService();

    const {
        getBankAccountsByBusinessNameAndBankAccountReason,
        fetchingGetBankAccountsByBusinessNameAndBankAccountReason
    } = useBusinessNameBankAccountService()

    const [loadingSenderBusinessName, setLoadingSenderBusinessName] = useState<boolean>(fetchingGetBusinessNameList);
    const [loadingSenderBankAccount, setLoadingSenderBankAccount] = useState<boolean>(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
    const [loadingReceiverBusinessName, setLoadingReceiverBusinessName] = useState<boolean>(fetchingGetBusinessNameList);
    const [loadingReceiverBankAccount, setLoadingReceiverBankAccount] = useState<boolean>(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);

    useEffect(() => {
        create();
    }, []);

   
    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createCheckingAccount(transferType, {
            onSuccess: (response: ServiceResponse) => {
                
                if(transferType === TransferTypes.TRANSFER){
                    setSenderBusinessNames(response.data.sender_business_names);
                }
                else{
                    setReceiverBusinessNames(response.data.receiver_business_names);
                }
                setSenderBusinessEntities(response.data.business_entities);
                setSenderBanks(response.data.banks);
                setSenderBankAccountTypes(response.data.bank_account_types);
                setSenderBankAccountReasons(response.data.bank_account_reasons);
                setReceiverBusinessEntities(response.data.business_entities);
                setReceiverBanks(response.data.banks);
                setReceiverBankAccountTypes(response.data.bank_account_types);
                setReceiverBankAccountReasons(response.data.bank_account_reasons);
                setCurrencies(response.data.currencies);
                
                const currency_id = response.data.currencies.find((currency : any) => String(currency.name).toUpperCase() === 'CLP')?.id ?? 0;
                
                setCheckingAccount({
                    ...checkingAccount,
                    currency_id: currency_id,
                });
                
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {

        if(transferType === TransferTypes.PAY){

            if (checkingAccount.sender_business_entity == undefined) {
                senderBusinessEntitySelected.current = undefined;
            }
            if (checkingAccount.sender_business_entity && checkingAccount.sender_business_entity != senderBusinessEntitySelected.current) {
                setLoadingSenderBusinessName(true);
                getBusinessNameList(checkingAccount.sender_business_entity, {
                    onSuccess: (response: ServiceResponse) => {
    
                        senderBusinessEntitySelected.current = checkingAccount.sender_business_entity ?? '';
                        setErrorFields(undefined);
                        setSenderBusinessNames(response.data.business_names);
                        setCheckingAccount(
                            {
                                ...checkingAccount,
                                sender_business_name_id: 0,
                                sender_bank_account_reason_id: 0,
                                sender_bank_account_id: 0,
                                sender_bank_id: 0,
                                sender_account_type_id: 0,
                                sender_account_number: ''
                            }
                        )
                        setLoadingSenderBusinessName(fetchingGetBusinessNameList);
                    },
                    onError: (response: ServiceResponse) => {
                        setCheckingAccount({
                            ...checkingAccount,
                            sender_business_entity: senderBusinessEntitySelected.current
                        });
                        setLoadingSenderBusinessName(fetchingGetBusinessNameList);
                    }
                });
            }
        }
        
    }, [checkingAccount.sender_business_entity]);

    useEffect(() => {

        if(transferType === TransferTypes.TRANSFER){
            
            if (checkingAccount.receiver_business_entity == undefined) {
                receiverBusinessEntitySelected.current = undefined;
            }
            if (checkingAccount.receiver_business_entity && checkingAccount.receiver_business_entity != receiverBusinessEntitySelected.current) {
                setLoadingReceiverBusinessName(true);
                getBusinessNameList(checkingAccount.receiver_business_entity, {
                    onSuccess: (response: ServiceResponse) => {
    
                        receiverBusinessEntitySelected.current = checkingAccount.receiver_business_entity ?? '';
                        setErrorFields(undefined);
                        setReceiverBusinessNames(response.data.business_names);
                        setCheckingAccount(
                            {
                                ...checkingAccount,
                                receiver_business_name_id: 0,
                                receiver_bank_account_reason_id: 0,
                                receiver_bank_account_id: 0,
                                receiver_bank_id: 0,
                                receiver_account_type_id: 0,
                                receiver_account_number: ''
                                
                            }
                        )
                        setLoadingReceiverBusinessName(fetchingGetBusinessNameList);
                    },
                    onError: (response: ServiceResponse) => {
                        setCheckingAccount({
                            ...checkingAccount,
                            receiver_business_entity: receiverBusinessEntitySelected.current
                        });
                        setLoadingReceiverBusinessName(fetchingGetBusinessNameList);
                    }
                });
            }

        }
        
    }, [checkingAccount.receiver_business_entity]);


    useEffect(() => {
        if (checkingAccount.sender_business_name_id == undefined) {
            senderBusinessNameIdSelected.current = undefined;
        }

        if (checkingAccount.sender_bank_account_reason_id == undefined) {
            senderBankAccountReasonIdSelected.current = undefined;
        }

        if (checkingAccount.sender_business_name_id && checkingAccount.sender_bank_account_reason_id) {
            setLoadingSenderBankAccount(true);
            getBankAccountsByBusinessNameAndBankAccountReason(
                checkingAccount.sender_business_name_id,
                checkingAccount.sender_bank_account_reason_id,
                {
                    onSuccess: (response: ServiceResponse) => {
                        senderBusinessNameIdSelected.current = checkingAccount.sender_business_name_id ?? 0;
                        senderBankAccountReasonIdSelected.current = checkingAccount.sender_bank_account_reason_id ?? 0;
                        setErrorFields(undefined);
                        setSenderBankAccounts(response.data.bank_accounts);
                        setCheckingAccount({
                            ...checkingAccount,
                            sender_bank_account_id: 0,
                            sender_bank_id: 0,
                            sender_account_type_id: 0,
                            sender_account_number: ''
                        });
                        setLoadingSenderBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
                    },
                    onError: (response: ServiceResponse) => {
                        setCheckingAccount({
                            ...checkingAccount,
                            sender_business_name_id: senderBusinessNameIdSelected.current,
                            sender_bank_account_reason_id: senderBankAccountReasonIdSelected.current
                        });
                        setLoadingSenderBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason);
                    }
                }
            );
        }
    }, [checkingAccount.sender_business_name_id, checkingAccount.sender_bank_account_reason_id]);



    useEffect(() => {
        if (checkingAccount.receiver_business_name_id == undefined) {
            receiverBusinessNameIdSelected.current = undefined;
        }

        if (checkingAccount.receiver_bank_account_reason_id == undefined) {
            receiverBankAccountReasonIdSelected.current = undefined;
        }

        if (checkingAccount.receiver_business_name_id && checkingAccount.receiver_bank_account_reason_id) {
            setLoadingReceiverBankAccount(true);
            getBankAccountsByBusinessNameAndBankAccountReason(
                checkingAccount.receiver_business_name_id,
                checkingAccount.receiver_bank_account_reason_id,
                {
                    onSuccess: (response: ServiceResponse) => {
                        receiverBusinessNameIdSelected.current = checkingAccount.receiver_business_name_id ?? 0;
                        receiverBankAccountReasonIdSelected.current = checkingAccount.receiver_bank_account_reason_id ?? 0;
                        setErrorFields(undefined);
                        setReceiverBankAccounts(response.data.bank_accounts);
                        setCheckingAccount({
                            ...checkingAccount,
                            receiver_bank_account_id: 0,
                            receiver_bank_id: 0,
                            receiver_account_type_id: 0,
                            receiver_account_number: ''
                        });
                        setLoadingReceiverBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason)
                    },
                    onError: (response: ServiceResponse) => {
                        setCheckingAccount({
                            ...checkingAccount,
                            receiver_business_name_id: receiverBusinessNameIdSelected.current,
                            receiver_bank_account_reason_id: receiverBankAccountReasonIdSelected.current
                        });
                        setLoadingReceiverBankAccount(fetchingGetBankAccountsByBusinessNameAndBankAccountReason)
                    }
                }
            );
        }
    }, [checkingAccount.receiver_business_name_id, checkingAccount.receiver_bank_account_reason_id]);


    useEffect(() => {

        const senderAccount = senderBankAccounts.find((bankAccount: BusinessNameBankAccount) => bankAccount.id === checkingAccount.sender_bank_account_id)

            if(senderAccount){

                setCheckingAccount({
                    ...checkingAccount,
                    sender_bank_id: senderAccount.bank_id,
                    sender_account_type_id: senderAccount.account_type_id,
                    sender_account_number: senderAccount.account_number

                });
            }

    }, [checkingAccount.sender_bank_account_id])


    useEffect(() => {

        const receiverAccount = receiverBankAccounts.find((bankAccount: BusinessNameBankAccount) => bankAccount.id === checkingAccount.receiver_bank_account_id)

            if(receiverAccount){

                setCheckingAccount({
                    ...checkingAccount,
                    receiver_bank_id: receiverAccount.bank_id,
                    receiver_account_type_id: receiverAccount.account_type_id,
                    receiver_account_number: receiverAccount.account_number

                });
            }

    }, [checkingAccount.receiver_bank_account_id])

    

    const store = () => {
        if (showLoading) showLoading('loading', `Guardando ${transferType === TransferTypes.TRANSFER ? "transferencia" : "pago"}...`);
        storeCheckingAccount(checkingAccount, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountForm
                        checkingAccountDataForm={checkingAccount}
                        setCheckingAccountDataForm={setCheckingAccount}
                        transferType={transferType}
                        senderBusinessNames={senderBusinessNames}
                        senderBusinessEntities={senderBusinessEntities}
                        senderBankAccountReasons={senderBankAccountReasons}
                        senderBankAccounts={senderBankAccounts}
                        senderBanks={senderBanks}
                        senderBankAccountTypes={senderBankAccountTypes}
                        receiverBusinessNames={receiverBusinessNames}
                        receiverBusinessEntities={receiverBusinessEntities}
                        receiverBankAccountReasons={receiverBankAccountReasons}
                        receiverBankAccounts={receiverBankAccounts}
                        receiverBanks={receiverBanks}
                        receiverBankAccountTypes={receiverBankAccountTypes}
                        currencies={currencies}
                        errorFields={errorFields}
                        loadingSenderBusinessName={loadingSenderBusinessName}
                        loadingSenderBankAccount={loadingSenderBankAccount}
                        loadingReceiverBusinessName={loadingReceiverBusinessName}
                        loadingReceiverBankAccount={loadingReceiverBankAccount}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreCheckingAccount}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreCheckingAccount} />
                </div>
            </div>
        </>
    );
};

export default CheckingAccountCreate;
