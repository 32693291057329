import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { PurchaseOrder, PurchaseorderDataForm, PurchaseOrderFilters } from '../../models/PurchaseOrder';

const usePurchaseOrderService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetPurchaseOrders, setFetchingGetPurchaseOrders] = useState(false);
    const [fetchingShowPurchaseOrder, setFetchingShowPurchaseOrder] = useState(false);
    const [fetchingCreatePurchaseOrder, setFetchingCreatePurchaseOrder] = useState(false);
    const [fetchingStorePurchaseOrder, setFetchingStorePurchaseOrder] = useState(false);
    const [fetchingEditPurchaseOrder, setFetchingEditPurchaseOrder] = useState(false);
    const [fetchingUpdatePurchaseOrder, setFetchingUpdatePurchaseOrder] = useState(false);
    const [fetchingDeletePurchaseOrder, setFetchingDeletePurchaseOrder] = useState(false);
    const [fetchingActivePurchaseOrder, setFetchingActivePurchaseOrder] = useState(false);
    const [fetchingChangeStatusPurchaseOrder, setFetchingChangeStatusPurchaseOrder] = useState(false);
    const [fetchingHasFinishedStatuses, setFetchingHasFinishedStatuses] = useState(false);
    const [fetchingGetPurchaseOrdersBySocietyAndProvider, setFetchingGetPurchaseOrdersBySocietyAndProvider] = useState(false);

    const getPurchaseOrders = (purchaseOrderFilters: PurchaseOrderFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(purchaseOrderFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PURCHASE_ORDER.GET_PURCHASE_ORDERS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPurchaseOrders
        });
    };

    const showPurchaseOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER.SHOW_PURCHASE_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingShowPurchaseOrder
        });
    };

    const createPurchaseOrder = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER.CREATE_PURCHASE_ORDER,
            setFetching: setFetchingCreatePurchaseOrder
        });
    };

    const storePurchaseOrder = (purchaseOrder: PurchaseorderDataForm, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PURCHASE_ORDER.STORE_PURCHASE_ORDER,
            body: purchaseOrder,
            setFetching: setFetchingStorePurchaseOrder
        });
    };

    const editPurchaseOrder = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER.EDIT_PURCHASE_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingEditPurchaseOrder
        });
    };

    const updatePurchaseOrder = (id: number, purchaseOrder: PurchaseorderDataForm, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PURCHASE_ORDER.UPDATE_PURCHASE_ORDER.replace(':id', id.toString()),
            body: purchaseOrder,
            setFetching: setFetchingUpdatePurchaseOrder
        });
    };

    const deletePurchaseOrder = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PURCHASE_ORDER.DELETE_PURCHASE_ORDER.replace(':id', id.toString()),
            setFetching: setFetchingDeletePurchaseOrder
        });
    };

    const hasAllFinishedStatuses = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER.HAS_ALL_FINISHED_STATUSES.replace(
                ':id',
                id.toString()
            ).replace(':purchase_order_status', status),
            setFetching: setFetchingHasFinishedStatuses
        });
    };

    const changeStatusPurchaseOrder = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PURCHASE_ORDER.CHANGE_STATUS_PURCHASE_ORDER.replace(':id', id.toString()),
            body: { status: status },
            setFetching: setFetchingChangeStatusPurchaseOrder
        });
    };

    const getPurchaseOrderBySocietyAndProvider = (society_id: number, provider_id: number, id: number, events: ServiceEvents = {}) => {
       
        doGet({
            ...events,
            url: EndPoints.PURCHASE_ORDER.GET_PURCHASE_ORDERS_BY_SOCIETY_AND_PROVIDER.replace(
                ':society_id', society_id.toString()
                ).replace(':provider_id', provider_id.toString()
                ).replace(':id', id.toString()),
            setFetching: setFetchingGetPurchaseOrdersBySocietyAndProvider
        });
    };


    

    return {
        fetchingGetPurchaseOrders,
        fetchingShowPurchaseOrder,
        fetchingCreatePurchaseOrder,
        fetchingStorePurchaseOrder,
        fetchingEditPurchaseOrder,
        fetchingUpdatePurchaseOrder,
        fetchingDeletePurchaseOrder,
        fetchingActivePurchaseOrder,
        fetchingHasFinishedStatuses,
        fetchingChangeStatusPurchaseOrder,
        fetchingGetPurchaseOrdersBySocietyAndProvider,
        getPurchaseOrders,
        showPurchaseOrder,
        createPurchaseOrder,
        storePurchaseOrder,
        editPurchaseOrder,
        updatePurchaseOrder,
        deletePurchaseOrder,
        hasAllFinishedStatuses,
        changeStatusPurchaseOrder,
        getPurchaseOrderBySocietyAndProvider
    };
};

export default usePurchaseOrderService;
