import React, { useEffect, useRef, useState } from 'react';

import { defaultDispatchProgram, DispatchProgram } from '../../../app/models/DispatchProgram';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import DispatchProgramForm from './forms/DispatchProgramForm';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import { Currency } from '../../../app/models/Currency';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import { RunnerDataForm } from '../../../app/models/Runner';
import { useHistory } from 'react-router-dom';
import { QuoteReferenceDataForm } from '../../../app/models/Quote';
import useSweetAlert from '../../../hooks/useSweetAlert';
import useNavigationPage from '../../../hooks/useNavigationPage';
import useClientService from '../../../app/services/hooks/useClientService';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DispatchProgramCreate = ({ onSaved, onCancel, onError }: Props) => {
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    // const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    // const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [transportationOrders, setTransportationOrders] = useState<TransportationOrderDataForm[]>(
        []
    );
    const [runners, setRunners] = useState<RunnerDataForm[]>([]);

    // const [currencies, setCurrencies] = useState<Currency[]>([]);
    // const [references, setReferences] = useState<QuoteReferenceDataForm[]>([]);
    const { navigationPage } = useNavigationPage();

    const {
        fetchingStoreDispatchProgram,
        storeDispatchProgram,
        fetchingCreateDispatchProgram,
        createDispatchProgram,
        fetchingShowTransportationOrderDispatchProgram,
        showTransportationOrderDispatchProgram
    } = useDispatchProgramService();

    const {
        getClientsBySociety,
        fetchingGetClientBySociety
    } = useClientService()

    const [dispatchProgram, setDispatchProgram] = useState<DispatchProgram>(defaultDispatchProgram);

    const dispatchProgramFromToi = useRef<DispatchProgram|undefined>();
    const clientsFromToi = useRef<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        createDispatchProgram({
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                // setPaymentMethods(response.data.payment_methods);
                // setPaymentsConditions(response.data.payment_conditions);
                // setCurrencies(response.data.currencies);
                setTransportationOrders(response.data.transportation_orders);
                setRunners(response.data.runners);
                // setReferences(response.data.references);
            }
        });
    }, []);

    useEffect(() => {
        if (dispatchProgram.transportation_order_item_id) {
            showTransportationOrderDispatchProgram(
                dispatchProgram.transportation_order_item_id as number,
                {
                    onSuccess: (response: ServiceResponse) => {
                        setErrorFields(undefined);

                        if(response.data.transportation_order.society_id){
                            societyIdSelected.current = Number(response.data.transportation_order.society_id) ?? 0;
                        }
                        
                        setClients(response.data.clients);
                        clientsFromToi.current = response.data.clients;
                        setDispatchProgram({
                            ...dispatchProgram,
                            ...response.data.transportation_order
                        });
                        dispatchProgramFromToi.current = {
                            ...dispatchProgram,
                            ...response.data.transportation_order
                        }

                        
                    },
                    onError: (response: ServiceResponse) => {
                        setDispatchProgram({
                            ...dispatchProgram,
                            transportation_order_item_id: dispatchProgramFromToi.current?.transportation_order_item_id ?? 0
                        });
                    }
                }
            );
        }
    }, [dispatchProgram.transportation_order_item_id]);


    useEffect(() => {

        if (dispatchProgram.society_id == undefined) {
            societyIdSelected.current = undefined;
        }

        if (dispatchProgram.society_id && dispatchProgram.society_id != societyIdSelected.current) {

            if((dispatchProgramFromToi.current && 
                dispatchProgramFromToi.current.transportation_order_item_id &&
                dispatchProgramFromToi.current.society_id != dispatchProgram.society_id) ||
                (!dispatchProgramFromToi.current)
            ){
                getClientsBySociety(Number(dispatchProgram.society_id), {
                    onSuccess: (response: ServiceResponse) => {
                        societyIdSelected.current = Number(dispatchProgram.society_id) ?? 0;
                     
                        setClients(response.data.clients);
                        setDispatchProgram(
                            {
                                ...dispatchProgram,
                                client_id: 0
                            }
                        )
                    },
                    onError: (response: ServiceResponse) => {
                        setDispatchProgram({
                            ...dispatchProgram,
                            society_id: Number(societyIdSelected.current)
                        });
                    }
                })
            }
            else if(
                (dispatchProgramFromToi.current && 
                    dispatchProgramFromToi.current.transportation_order_item_id &&
                    dispatchProgramFromToi.current.society_id == dispatchProgram.society_id)
            ){
                societyIdSelected.current = Number(dispatchProgram.society_id) ?? 0;
                setClients(clientsFromToi.current);
                setDispatchProgram(
                    {
                        ...dispatchProgram,
                        client_id: dispatchProgramFromToi.current.client_id
                    }
                )

            }
            
            
        }
        
    }, [dispatchProgram.society_id]);

    const store = () => {
        useSweetAlert().spinnerAlert('Guardando despacho...');
        storeDispatchProgram(dispatchProgram, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);
                navigationPage('/dispatch-programs/' + response.data.dispatch_program_id + '/edit');
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateDispatchProgram || fetchingShowTransportationOrderDispatchProgram ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <DispatchProgramForm
                        dispatchProgramForm={dispatchProgram}
                        setDispatchProgramForm={setDispatchProgram}
                        societies={societies}
                        clients={clients}
                        // paymentConditions={paymentConditions}
                        // paymentMethods={paymentMethods}
                        // currencies={currencies}
                        errorFields={errorFields}
                        transportationOrders={transportationOrders}
                        runners={runners}
                        loadingClient={fetchingGetClientBySociety}
                        // references={references}
                        // isCreated={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingStoreDispatchProgram}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreDispatchProgram} />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramCreate;
