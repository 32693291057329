import React, { useEffect, useState } from 'react';

import { Carrier, defaultCarrier } from '../../../app/models/Carrier';
import useCarrierService from '../../../app/services/hooks/useCarrierService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import CarrierFormContainer from './CarrierFormContainer';
import LazyLoading from '../../../components/LazyLoading';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const CarrierCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { fetchingStoreCarrier, storeCarrier, createCarrier, fetchingCreateCarrier } =
        useCarrierService();

    const [Carrier, setCarrier] = useState<Carrier>(defaultCarrier);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        createCarrier({
            onSuccess: (response: ServiceResponse) => {
                defaultCarrier.documents = [];
                setCarrier(defaultCarrier);
            }
        });
    }, []);

    const store = () => {
        storeCarrier(Carrier, {
            onSuccess: (response: ServiceResponse) => {
                toast.success(response.message);
                if (onSaved) onSaved();
            },
            onError: () => {
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateCarrier ? (
        <LazyLoading height={300} />
    ) : (
        <CarrierFormContainer
            fetching={fetchingStoreCarrier}
            action={store}
            cancel={cancel}
            Carrier={Carrier}
            setCarrier={setCarrier}
            errorFields={errorFields}
        />
    );
};

export default CarrierCreate;
