import React from 'react';
import { IconProps } from '../types';

export const Providers: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M9 24H5V21C5 20.4477 5.44772 20 6 20H8C8.55229 20 9 20.4477 9 21V24ZM15 10.382L22.5528 7.10557C23.2177 6.77312 24 7.25662 24 8V23C24 23.5523 23.5523 24 23 24H1C0.447715 24 0 23.5523 0 23V1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1V10.382L13.5528 7.10557C14.2177 6.77312 15 7.25662 15 8V10.382ZM6 15C6.55228 15 7 15.4477 7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15ZM14 15C14.5523 15 15 15.4477 15 16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15ZM10 15C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17C9.44771 17 9 16.5523 9 16C9 15.4477 9.44771 15 10 15ZM18 15C18.5523 15 19 15.4477 19 16C19 16.5523 18.5523 17 18 17C17.4477 17 17 16.5523 17 16C17 15.4477 17.4477 15 18 15Z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Providers;
