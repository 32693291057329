import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useTransportationOrderService from '../../app/services/hooks/useTransportationOrderService';
import {
    TransportationOrderDataForm,
    TransportationOrderFilters
} from '../../app/models/TransportationOrder';
import moment from 'moment';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import useSweetAlert from '../../hooks/useSweetAlert';
import DefaultModal from '../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import TransportationOrderCreate from './components/TransportationOrderCreate';
import { AuthContext } from '../../contexts/AuthContext';
import { Roles } from '../../app/shared/enums';
import TransportationOrderDataTable from './components/tables/TransportationOrderDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';
import { TransportationOrderStatus } from '../../app/models/TransportationOrderStatus';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Pedidos de Transporte',
        url: '/transportation-orders',
        isActive: true
    }
];

const TransportationOrder = () => {
    const history = useHistory();
    const {
        fetchingGetTransportationOrders,
        getTransportationOrders,
        deleteTransportationOrder,
        getExcelAllTransportationOrders
    } = useTransportationOrderService();

    const { auth } = useContext(AuthContext);
    const { navigationPage } = useNavigationPage();

    const [transportationOrders, setTransportationOrders] = useState<TransportationOrderDataForm[]>(
        []
    );
    const [statuses, setStatuses] = useState<TransportationOrderStatus[]>([]);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<TransportationOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter]);

    const reloadTable = () => {
        getTransportationOrders(filter, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationOrders(response.data.transportation_orders);
                setStatuses(response.data.statuses);
                setTotalRows(response.data.total_rows);
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };
    const downloadExcel = () => {
        getExcelAllTransportationOrders(filter);
    };
    const edit = (id?: number) => {
        navigationPage('/transportation-orders/' + id + '/edit');
    };

    const show = (id?: number) => {
        navigationPage('/transportation-orders/' + id + '/show');
    };

    const destroy = (transportationOrderId: number) => {
        const _text = 'Está a punto de eliminar el pedido de transporte #' + transportationOrderId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                useSweetAlert().spinnerAlert('Eliminando pedido...');
                deleteTransportationOrder(transportationOrderId, {
                    onSuccess: (response: ServiceResponse) => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: response.message
                            // text:
                            //     'Se ha eliminado el pedido de transporte #' + transportationOrderId
                        });
                        reloadTable();
                    },
                    onError: (response: ServiceResponse) => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: response.message
                            // text:
                            //     'No se ha podido eliminar el pedido de transporte #' + transportationOrderId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El pedido de transporte no se ha eliminado.'
                });
            }
        });
    };

    const document = (quoteId: number) => {
        // getDocumentQuote(quoteId, {});
    };

    return (
        <>
            <Breadcrumbs pageSection="Pedidos de Transporte" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                {auth?.roles && !auth?.roles.includes(Roles.RUNNER) && (
                                    <ButtonCreate
                                        callbackCreate={showCreate}
                                        title="Nuevo Pedido de Transporte"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <TransportationOrderDataTable
                            transportationOrders={transportationOrders}
                            totalRows={totalRows}
                            loading={fetchingGetTransportationOrders}
                            destroy={destroy}
                            edit={/*showEdit*/ edit}
                            filter={filter}
                            setFilter={setFilter}
                            show={show}
                            downloadExcel={downloadExcel}
                            statuses={statuses}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Pedido de Transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderCreate
                        onSaved={() => {
                        
                            reloadTable();
                      
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportationOrder;
