import React, { useEffect, useRef, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { BankAccount, BankAccountType } from '../../../../app/models/BankAccountType';
import { Currency } from '../../../../app/models/Currency';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';
import { formatAmount, formatMoney, setCleanTextAreaError } from '../../../../utils/utils';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';
import { Bank } from '../../../../app/models/Bank';
import { CheckingAccountDataForm } from '../../../../app/models/CheckingAccount';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import { BankAccountReason } from '../../../../app/models/BankAccountReason';
import { BusinessNameBankAccount } from '../../../../app/models/BusinessNameBankAccount';
import { TransferTypes } from '../../../../app/shared/enums';

type Props = {
    checkingAccountDataForm: CheckingAccountDataForm;
    setCheckingAccountDataForm: (checkingAccountDataForm: CheckingAccountDataForm) => void;
    transferType: string;
    senderBusinessNames: BusinessName[];
    senderBusinessEntities: BusinessEntity[];
    senderBankAccountReasons: BankAccountReason[];
    senderBankAccounts: BusinessNameBankAccount[];
    senderBanks: Bank[];
    senderBankAccountTypes: BankAccountType[];
    receiverBusinessNames: BusinessName[];
    receiverBusinessEntities: BusinessEntity[];
    receiverBankAccountReasons: BankAccountReason[];
    receiverBankAccounts: BusinessNameBankAccount[];
    receiverBanks: Bank[];
    receiverBankAccountTypes: BankAccountType[];
    currencies: Currency[];
    errorFields?: any;
    loadingSenderBusinessName: boolean;
    loadingSenderBankAccount: boolean;
    loadingReceiverBusinessName: boolean;
    loadingReceiverBankAccount: boolean;
};

const CheckingAccountForm = ({
    checkingAccountDataForm,
    setCheckingAccountDataForm,
    transferType,
    senderBusinessNames,
    senderBusinessEntities,
    senderBankAccountReasons,
    senderBankAccounts,
    senderBanks,
    senderBankAccountTypes,
    receiverBusinessNames,
    receiverBusinessEntities,
    receiverBankAccountReasons,
    receiverBankAccounts,
    receiverBanks,
    receiverBankAccountTypes,
    currencies,
    errorFields,
    loadingSenderBusinessName,
    loadingSenderBankAccount,
    loadingReceiverBusinessName,
    loadingReceiverBankAccount
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();
   
    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setCheckingAccountDataForm({ ...checkingAccountDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, name: string) => {
        if (e) {
            
            const obj = { [name]:  e[0]};
            
            console.log(obj)
            setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        setCheckingAccountDataForm({ ...checkingAccountDataForm, ...obj });
    };

    const handleRemoveFile = (name: string) => {

        setCheckingAccountDataForm({
            ...checkingAccountDataForm,
            [name]: undefined
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }

    };


    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label"> Fecha de emisión </label>
                    <input
                        type="date"
                        className={`form-control ${fieldHasError('issue_date')}`}
                        name="issue_date"
                        id="issue_date"
                        value={checkingAccountDataForm.issue_date}
                        onChange={handleChange}
                        placeholder="Ingrese una fecha de emisión"
                        onFocus={() => onFocusRemove('issue_date')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('issue_date')}
                    </div>
                </div>
            </div>
           <hr />
           <h4>Datos Emisor</h4>
           {
            transferType === TransferTypes.PAY &&

            <div className="mb-2 row">
                <div className="col-md-qw">
                    <label className="col-form-label">Entidad</label>

                    <SelectTwo
                        name="sender_business_entity"
                        id="sender_business_entity"
                        inputValue={checkingAccountDataForm.sender_business_entity}
                        options={SelectTwoMapperOptions(senderBusinessEntities)}
                        hasError={fieldHasError('sender_business_entity') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('sender_business_entity', value?.value)
                        }
                        placeholder={'Seleccione entidad'}
                        onFocus={() => onFocusRemove('sender_business_entity')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('sender_business_entity')}
                    </div>
                </div>
            </div>

           }
           
                {
                    loadingSenderBusinessName?

                    <LazyLoading height={300} />

                    :

                    <>
                        <div className="mb-2 row">
                            <div className="col-md-12">
                                <label className="col-form-label">Razón social</label>
                                <SelectTwo
                                    name="sender_business_name_id"
                                    id="sender_business_name_id"
                                    inputValue={checkingAccountDataForm.sender_business_name_id}
                                    options={SelectTwoMapperOptions(senderBusinessNames, 'id', ['name'])}
                                    hasError={fieldHasError('sender_business_name_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('sender_business_name_id', value?.value)
                                    }
                                    placeholder={'Seleccione razón social'}
                                    onFocus={() => onFocusRemove('sender_business_name_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('sender_business_name_id')}
                                </div>
                            </div>
                        </div>
                        <div className="mb-2 row">
                            <div className="col-md-12">
                                <label className="col-form-label">Motivo cuenta</label>
                                <SelectTwo
                                    name="sender_bank_account_reason_id"
                                    id="sender_bank_account_reason_id"
                                    inputValue={checkingAccountDataForm.sender_bank_account_reason_id}
                                    options={SelectTwoMapperOptions(senderBankAccountReasons)}
                                    hasError={fieldHasError('sender_bank_account_reason_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('sender_bank_account_reason_id', value?.value)
                                    }
                                    placeholder={'Seleccione motivo'}
                                    onFocus={() => onFocusRemove('sender_bank_account_reason_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('sender_bank_account_reason_id')}
                                </div>
                            </div>
                        </div>
                    {
                        loadingSenderBankAccount?
                        <LazyLoading height={300} />
                        :
                        <>
                        <div className="mb-2 row">
                         <div className="col-md-12">
                            <label className="col-form-label">Cuenta bancaria</label>
                            <SelectTwo
                                name="sender_bank_account_id"
                                id="sender_bank_account_id"
                                inputValue={checkingAccountDataForm.sender_bank_account_id}
                                options={SelectTwoMapperOptions(senderBankAccounts)}
                                hasError={fieldHasError('sender_bank_account_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_bank_account_id', value?.value)
                                }
                                placeholder={'Seleccione c. Bancaria'}
                                onFocus={() => onFocusRemove('sender_bank_account_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_bank_account_id')}
                            </div>
                        </div>
                        </div>
                        <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-form-label">Banco</label>
                            <SelectTwo
                                name="sender_bank_id"
                                id="sender_bank_id"
                                inputValue={checkingAccountDataForm.sender_bank_id}
                                options={SelectTwoMapperOptions(senderBanks)}
                                hasError={fieldHasError('sender_bank_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_bank_id', value?.value)
                                }
                                placeholder={'Seleccione banco'}
                                onFocus={() => onFocusRemove('sender_bank_id')}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_bank_id')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-form-label">Tipo de cuenta</label>
                            <SelectTwo
                                name="sender_account_type_id"
                                id="sender_account_type_id"
                                inputValue={checkingAccountDataForm.sender_account_type_id}
                                options={SelectTwoMapperOptions(senderBankAccountTypes)}
                                hasError={fieldHasError('sender_account_type_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('sender_account_type_id', value?.value)
                                }
                                placeholder={'Seleccione tipo'}
                                onFocus={() => onFocusRemove('sender_account_type_id')}
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_account_type_id')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">N° de cuenta</label>
                            <input
                                name="sender_account_number"
                                id="sender_account_number"
                                type="text"
                                onChange={handleChange}
                                className={`form-control ${fieldHasError('sender_account_number')}`}
                                value={checkingAccountDataForm.sender_account_number}
                                onFocus={() => onFocusRemove('sender_account_number')}
                                placeholder="Ingrese el número de cuenta"
                                disabled={true}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('sender_account_number')}
                            </div>
                        </div>
                    </div>
                        </>
                    }
                   
                    </>
                

                }
            
            <hr />
            <h4>Datos Receptor</h4>

            {
                transferType === TransferTypes.TRANSFER &&

                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Entidad</label>

                        <SelectTwo
                            name="receiver_business_entity"
                            id="receiver_business_entity"
                            inputValue={checkingAccountDataForm.receiver_business_entity}
                            options={SelectTwoMapperOptions(receiverBusinessEntities)}
                            hasError={fieldHasError('receiver_business_entity') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('receiver_business_entity', value?.value)
                            }
                            placeholder={'Seleccione entidad'}
                            onFocus={() => onFocusRemove('receiver_business_entity')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('receiver_business_entity')}
                        </div>
                    </div>
                </div>
            }
            {
                    loadingReceiverBusinessName?
                    <LazyLoading height={300} />
                    :
                    <>
                        <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Razón social</label>

                            <SelectTwo
                                name="receiver_business_name_id"
                                id="receiver_business_name_id"
                                inputValue={checkingAccountDataForm.receiver_business_name_id}
                                options={SelectTwoMapperOptions(receiverBusinessNames, 'id', ['name'])}
                                hasError={fieldHasError('receiver_business_name_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('receiver_business_name_id', value?.value)
                                }
                                placeholder={'Seleccione razón social'}
                                onFocus={() => onFocusRemove('receiver_business_name_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('receiver_business_name_id')}
                            </div>
                        </div>
                        </div>
                        <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-form-label">Motivo cuenta</label>
                            <SelectTwo
                                name="receiver_bank_account_reason_id"
                                id="receiver_bank_account_reason_id"
                                inputValue={checkingAccountDataForm.receiver_bank_account_reason_id}
                                options={SelectTwoMapperOptions(receiverBankAccountReasons)}
                                hasError={fieldHasError('receiver_bank_account_reason_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwo('receiver_bank_account_reason_id', value?.value)
                                }
                                placeholder={'Seleccione motivo'}
                                onFocus={() => onFocusRemove('receiver_bank_account_reason_id')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('receiver_bank_account_reason_id')}
                            </div>
                        </div>
                        </div>
                        

                        {
                            loadingReceiverBankAccount?
                            <LazyLoading height={300} />
                            :
                            <>
                            <div className="mb-2 row">
                                <div className="col-md-12">
                                <label className="col-form-label">Cuenta bancaria</label>

                                <SelectTwo
                                    name="receiver_bank_account_id"
                                    id="receiver_bank_account_id"
                                    inputValue={checkingAccountDataForm.receiver_bank_account_id}
                                    options={SelectTwoMapperOptions(receiverBankAccounts)}
                                    hasError={fieldHasError('receiver_bank_account_id') !== ''}
                                    onChange={(value: any) =>
                                        handleChangeSelectTwo('receiver_bank_account_id', value?.value)
                                    }
                                    placeholder={'Seleccione C. Bancaria'}
                                    onFocus={() => onFocusRemove('receiver_bank_account_id')}
                                />
                                <div className="invalid-feedback" style={{ display: 'flex' }}>
                                    {fieldErrorMessage('receiver_bank_account_id')}
                                </div>
                            </div>
                        </div>
                            <div className="mb-2 row">
                                <div className="col-md-6">
                                    <label className="col-form-label">Banco</label>

                                    <SelectTwo
                                        name="receiver_bank_id"
                                        id="receiver_bank_id"
                                        inputValue={checkingAccountDataForm.receiver_bank_id}
                                        options={SelectTwoMapperOptions(receiverBanks)}
                                        hasError={fieldHasError('receiver_bank_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('receiver_bank_id', value?.value)
                                        }
                                        placeholder={'Seleccione banco'}
                                        onFocus={() => onFocusRemove('receiver_bank_id')}
                                        disabled={true}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('receiver_bank_id')}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <label className="col-form-label">Tipo de cuenta</label>

                                    <SelectTwo
                                        name="receiver_account_type_id"
                                        id="receiver_account_type_id"
                                        inputValue={checkingAccountDataForm.receiver_account_type_id}
                                        options={SelectTwoMapperOptions(receiverBankAccountTypes)}
                                        hasError={fieldHasError('receiver_account_type_id') !== ''}
                                        onChange={(value: any) =>
                                            handleChangeSelectTwo('receiver_account_type_id', value?.value)
                                        }
                                        placeholder={'Seleccione tipo'}
                                        onFocus={() => onFocusRemove('receiver_account_type_id')}
                                        disabled={true}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('receiver_account_type_id')}
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2 row">
                                <div className="col-md-12">
                                    <label className="col-form-label">N° de cuenta</label>
                                    <input
                                        name="receiver_account_number"
                                        id="receiver_account_number"
                                        type="text"
                                        onChange={handleChange}
                                        className={`form-control ${fieldHasError('receiver_account_number')}`}
                                        value={checkingAccountDataForm.receiver_account_number}
                                        onFocus={() => onFocusRemove('receiver_account_number')}
                                        placeholder="Ingrese el número de cuenta"
                                        disabled={true}
                                    />
                                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                                        {fieldErrorMessage('receiver_account_number')}
                                </div>
                            </div>
                        </div>
                            </>
                        }
                    
                    
                    </>
            }
            
            <hr />
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Monto</label>
                    <input
                        name='amount'
                        id='amount'
                        onChange={handleChange}
                        value={checkingAccountDataForm.amount}
                        className={`form-control ${fieldHasError(`amount`)}`}
                        onFocus={() => onFocusRemove(`amount`)}
                        placeholder="Ingrese el monto"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('amount')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Moneda</label>
                    <SelectTwo
                        name="currency_id"
                        id="currency_id"
                        inputValue={checkingAccountDataForm.currency_id}
                        options={SelectTwoMapperOptions(currencies)}
                        hasError={fieldHasError('currency_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('currency_id', value?.value)
                        }
                        placeholder={'Seleccione moneda'}
                        onFocus={() => onFocusRemove('currency_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('currency_id')}
                    </div>
                </div>
            </div>
            
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Comprobante</label>
                    <InputButtonFile
                        handleChangeDocumentFile={handleChangeDocumentFile}
                        name={'file'}
                        handleRemoveFile={(value: any) => handleRemoveFile('file')}
                        objectFile={checkingAccountDataForm?.file}
                        onFocus={() => onFocusRemove('file')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('file')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-md-12 col-form-label">Descripción</label>
                    <textarea
                        className={`form-control ${fieldHasError('description')}`}
                        name="description"
                        id="description"
                        value={checkingAccountDataForm.description}
                        onChange={handleChange}
                        placeholder="Ingrese una descripción"
                        onFocus={setCleanTextAreaError}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('description')}
                    </div>
                </div>
            </div>


        </>
    );
};

export default CheckingAccountForm;
