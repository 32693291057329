import React from 'react';
import { IconProps } from '../types';

export const Sales: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M4.99992 21.9996C3.34309 21.9996 1.99997 20.6565 1.99997 18.9997C1.99997 17.6027 2.95482 16.4288 4.24746 16.0949L4.69032 14.7663C3.68243 14.3426 2.94957 13.3832 2.86086 12.2299L2.15631 3.07074C2.10983 2.46652 1.60599 1.99995 0.999985 1.99995C0.447708 1.99995 0 1.55225 0 0.99997C0 0.44769 0.447708 -0.000015 0.999985 -0.000015C2.65105 -0.000015 4.02375 1.27115 4.15038 2.91735L22.9996 2.94455C23.6303 2.94455 24.1034 3.52112 23.9804 4.13961L22.3006 12.585C22.0214 13.9887 20.7895 14.9998 19.3583 14.9998H6.72066L6.29035 16.2907C7.00898 16.6336 7.56976 17.2541 7.83398 18.0134C7.88794 18.0044 7.94336 17.9997 7.99988 17.9997H16.1705C16.5823 16.8345 17.6935 15.9997 18.9997 15.9997C20.6565 15.9997 21.9997 17.3429 21.9997 18.9997C21.9997 20.6565 20.6565 21.9996 18.9997 21.9996C17.6935 21.9996 16.5823 21.1649 16.1705 19.9997H7.99988C7.94336 19.9997 7.88794 19.995 7.83398 19.986C7.42604 21.1583 6.31124 21.9996 4.99992 21.9996zM4.98347 17.9998C4.43878 18.0086 3.99994 18.4529 3.99994 18.9997C3.99994 19.552 4.44765 19.9997 4.99992 19.9997C5.5522 19.9997 5.99991 19.552 5.99991 18.9997C5.99991 18.453 5.56114 18.0087 5.01652 17.9998C5.00552 18 4.99451 18 4.98347 17.9998zM18.9997 19.9997C19.552 19.9997 19.9997 19.552 19.9997 18.9997C19.9997 18.4474 19.552 17.9997 18.9997 17.9997C18.4474 17.9997 17.9997 18.4474 17.9997 18.9997C17.9997 19.552 18.4474 19.9997 18.9997 19.9997z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Sales;
