import { Place } from './Place';
import { Product } from './Product';
import { Unit } from './Unit';
import { Currency } from './Currency';
import { SplitOrderItem } from './TransportationOrderItem';

export interface TransportationSubOrderItemDataForm {
    transportation_order_id?: number;
    id?: number;
    description: string;
    service?: string;
    service_id?: number;
    status?: string;
    dispatch_date: string;
    product_name: string;
    product_rate: number;
    product_unit: string;
    quantity: number;
    rate?: number;
    destiny_id?: number;
    destiny?: Place;
    origin_id?: number;
    origin?: Place;
    product_id?: number;
    product?: Product;
    quantity_unit_id?: number;
    quantity_unit?: Unit;
    currency_id?: number;
    currency?: Currency;
    currency_name?: string;
    created_at?: Date | string;
    updated_at?: Date | string;
    quantity_available?: number;
    quantity_used?: number;
    missing_dispatch_quantity?: number;
}

export const defaultTransportationSubOrderItemDataForm: TransportationSubOrderItemDataForm = {
    transportation_order_id: undefined,
    id: undefined,
    description: '',
    service: '',
    service_id: 0,
    dispatch_date: '',
    product_name: '',
    product_rate: 0,
    product_unit: '',
    quantity: 0,
    rate: 0,
    destiny_id: 0,
    destiny: undefined,
    origin_id: 0,
    origin: undefined,
    product_id: 0,
    product: undefined,
    quantity_unit_id: 0,
    quantity_unit: undefined,
    currency_id: 0,
    currency: undefined,
    currency_name: '',
    status: '',
    created_at: '',
    updated_at: ''
};

export interface TransportationSubOrderItemShowDataForm {
    // quote_id?: number;
    id?: number;
    description?: string;
    dispatch_date?: string;
    product_name?: string;
    product_rate?: number;
    product_unit?: string;
    quantity?: number | string;
    quantity_unit?: number | string;
    rate?: number | string;
    service?: string;
    currency?: string;
    destiny?: number | string;
    origin?: number | string;
    created_at?: string;
    updated_at?: string;
}

export interface SplitSubOrderItem {
    index:number;
    quantity: number;
    dispatch_date: string;
}

export interface SplitDataForm {
    transportation_order_item_id: number;
    dispatch_program_id: number;
    dispatch_program_item: SplitOrderItem[];
}
