import React from 'react';
import { IconProps } from '../types';

export const Center: React.FC<IconProps> = ({ size = 18, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 472.615 472.615"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M0,0v472.615h169.956V333.26H302.66v139.356c-0.047,0,169.956,0,169.956,0V0H0z M211.31,275.525H50.844V184.8H211.31 V275.525z M211.31,135.712H50.844V44.986H211.31V135.712z M421.772,275.525H261.306V184.8h160.466V275.525z M421.772,135.712 H261.306V44.986h160.466V135.712z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
