import { Link } from 'react-router-dom';
import {
    InternalSettlement,
    InternalSettlementFilters,
    InternalSettlementTable
} from '../../../../app/models/InternalSettlement';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import { momentParseDate } from '../../../../helpers';
import { formatAmount, formatMoney, formatNumber } from '../../../../utils/utils';
import InternalSettlementFilter from './InternalSettlementFilter';
import LazyLoading from '../../../../components/LazyLoading';
import GetAllSummary from '../GetAllSummary';
import { BusinessName } from '../../../../app/models/BusinessName';
import { BusinessEntity } from '../../../../app/models/BusinessEntity';
import { CheckingAccountDataForm } from '../../../../app/models/CheckingAccount';

interface Props {
    internalSettlements: InternalSettlement[];
    totalRows: number;
    allCredit: number;
    allDebit: number;
    loading?: boolean;
    edit?: (id: number) => void;
    active?: (id: number, active: boolean) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
    paginationRowsPerPageOptions?: any[];
    downloadExcel?: () => void;
    businessNames: BusinessName[];
    businessEntitiesCompare: BusinessEntity[];
    businessNamesCompare: BusinessName[];
}

const InternalSettlementDataTable = ({
    internalSettlements,
    totalRows,
    allCredit,
    allDebit,
    loading = false,
    filter,
    setFilter,
    downloadExcel,
    show,
    businessNames,
    businessNamesCompare,
    businessEntitiesCompare
}: Props) => {
    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row.id,
            sortable: true,
            sortField: 'id',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.id}</span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            width: '130px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Entidad emisor',
            selector: (row: any) => row.sender_business_entity,
            sortable: true,
            sortField: 'sender_business_entity',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.sender_business_entity}</span>
            )
        },
        {
            name: 'Razón social emisor',
            selector: (row: any) => row.sender_business_name,
            sortable: true,
            sortField: 'sender_business_name',
            width: '130px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.sender_business_name ? String(row.sender_business_name) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Banco',
            selector: (row: any) => row.sender_bank,
            sortable: true,
            sortField: 'sender_bank',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.sender_bank ? String(row.sender_bank) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'N° cuenta emisor',
            selector: (row: any) => row.sender_account_number,
            sortable: true,
            sortField: 'sender_account_number',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.sender_account_number}</span>
            )
        },
        {
            name: 'Entidad receptor',
            selector: (row: any) => row.receiver_business_entity,
            sortable: true,
            sortField: 'receiver_business_entity',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.receiver_business_entity}</span>
            )
        },
        {
            name: 'Razón social receptor',
            selector: (row: any) => row.receiver_business_name,
            sortable: true,
            sortField: 'receiver_business_name',
            width: '130px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.receiver_business_name ? String(row.receiver_business_name) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'Banco',
            selector: (row: any) => row.receiver_bank,
            sortable: true,
            sortField: 'receiver_bank',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.receiver_bank ? String(row.receiver_bank) : ''
                        }}
                    />
                </span>
            )
        },
        {
            name: 'N° cuenta receptor',
            selector: (row: any) => row.receiver_account_number,
            sortable: true,
            sortField: 'receiver_account_number',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <span className="">{row.receiver_account_number}</span>
            )
        },
        {
            name: 'Monto',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            width: '115px',
            cell: (row: CheckingAccountDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.amount ? row.amount : 0)} {row.currency}
                    </span>
                </>
            )
        },
        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: InternalSettlementTable, index: any, column: any) => (
                <div className="">
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver gastos'}
                        />
                    )}
                </div>
            )
        }
    ];

    return (
        <>
            <div className="row mt-3">
                <div className="col-lg-6 ms-auto">
                    <GetAllSummary loading={loading} allCredit={allCredit} allDebit={allDebit} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-5">
                    <InternalSettlementFilter 
                        filter={filter} 
                        setFilter={setFilter} 
                        businessNames={businessNames} 
                        businessEntitiesCompare={businessEntitiesCompare}
                        businessNamesCompare={businessNamesCompare}
                    />
                </div>
                <div className="col-auto ms-auto mb-2" style={{ paddingTop: 22 }}>
                    <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                        <i className="fa fa-file-excel" /> Descargar
                    </button>
                </div>
            </div>

            <DefaultDataTable
                columns={columns}
                data={internalSettlements}
                progressPending={loading}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
            />
        </>
    );
};

export default InternalSettlementDataTable;
