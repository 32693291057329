import React from 'react';
import { IconProps } from '../types';

export const Carriers: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M24 7.4051V13C24 14.1046 23.1046 15 22 15H21.9646C21.7219 16.6961 20.2632 18 18.5 18C16.7368 18 15.2781 16.6961 15.0354 15H9.96456C9.72194 16.6961 8.26324 18 6.5 18C4.73676 18 3.27806 16.6961 3.03544 15H2C0.89543 15 0 14.1046 0 13V2C0 0.89543 0.89543 0 2 0H15C16.1046 0 17 0.89543 17 2V3H19.3139C20.0157 3 20.6953 3.24605 21.2344 3.69534L22.9206 5.10046C23.6045 5.67044 24 6.51479 24 7.4051zM22 8V7.4051C22 7.1083 21.8682 6.8269 21.6402 6.6369L19.954 5.23178C19.7743 5.08202 19.5478 5 19.3139 5H17V8H22zM6.5 16C7.32843 16 8 15.3284 8 14.5C8 13.6716 7.32843 13 6.5 13C5.67157 13 5 13.6716 5 14.5C5 15.3284 5.67157 16 6.5 16zM18.5 16C19.3284 16 20 15.3284 20 14.5C20 13.6716 19.3284 13 18.5 13C17.6716 13 17 13.6716 17 14.5C17 15.3284 17.6716 16 18.5 16z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Carriers;
