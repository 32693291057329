import React, { useContext, useEffect, useState } from 'react';

import { DailyCarrier, defaultDailyCarrier } from '../../../app/models/DailyCarrier';
import useDailyCarrierService from '../../../app/services/hooks/useDailyCarrierService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import DailyCarrierFormContainer from './DailyCarrierFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import { Truck } from '../../../app/models/Truck';
import { Carrier } from '../../../app/models/Carrier';
import { Provider } from '../../../app/models/Provider';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const DailyCarrierCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingStoreDailyCarrier,
        storeDailyCarrier,
        createDailyCarrier,
        fetchingCreateDailyCarrier
    } = useDailyCarrierService();
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [hoppers, setHoppers] = useState<Carrier[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [errorGetData, setErrorGetData] = useState('');

    const [dailyCarrier, setDailyCarrier] = useState<DailyCarrier>(defaultDailyCarrier);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createDailyCarrier({
            onSuccess: (response: ServiceResponse) => {
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHoppers(response.data.hoppers);
                setProviders(response.data.providers);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                setErrorGetData(response.message);
            }
        });
    }, []);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando Transporte...');
        storeDailyCarrier(dailyCarrier, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return fetchingCreateDailyCarrier ? (
        <LazyLoading height={300} />
    ) : (
        <DailyCarrierFormContainer
            fetching={fetchingStoreDailyCarrier}
            action={store}
            cancel={cancel}
            dailyCarrier={dailyCarrier}
            setDailyCarrier={setDailyCarrier}
            errorFields={errorFields}
            carriers={carriers}
            trucks={trucks}
            hoppers={hoppers}
            providers={providers}
        />
    );
};

export default DailyCarrierCreate;
