import React, { useEffect } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { Provider } from '../../../../app/models/Provider';
import { Society } from '../../../../app/models/Society';
import LazyLoading from '../../../../components/LazyLoading';
import { ServiceManifestDataForm } from '../../../../app/models/ServiceManifest';
import { PurchaseorderDataForm } from '../../../../app/models/PurchaseOrder';
import InputButtonFile from '../../../../components/inputs/InputButtonFile';

interface Props {
    serviceManifestDataForm: ServiceManifestDataForm;
    setServiceManifestDataForm: (serviceManifestDataForm: ServiceManifestDataForm) => void;
    societies: Society[];
    providers: Provider[];
    bill?: boolean;
    purchaseOrders: PurchaseorderDataForm[];
    errorFields?: any;
    purchaseOrdersLoading: boolean;
}

const ServiceManifestForm = ({
    serviceManifestDataForm,
    setServiceManifestDataForm,
    societies,
    providers,
    purchaseOrders,
    errorFields,
    bill = true,
    purchaseOrdersLoading
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setServiceManifestDataForm({ ...serviceManifestDataForm, [name]: value });
    };

    const handleChangeDocumentFile = (e: FileList | null, type: string) => {
        if (e) {
            setServiceManifestDataForm({ ...serviceManifestDataForm, ['file']: e[0] });
        }
    };

    const handleRemoveFile = (name: string) => {
        setServiceManifestDataForm({ 
            ...serviceManifestDataForm, 
            [name]: undefined 
        });
        const fileInput = document.getElementById(name) as HTMLInputElement | null;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null | undefined) => {
        const obj = { [name]: value };

        if (name === 'purchase_order_id') {
            const purchase_order = purchaseOrders.find((p) => p.id === value);
            obj.society_rut = purchase_order?.society_rut;
            obj.society_business_name = purchase_order?.society_business_name;
            obj.provider_rut = purchase_order?.provider_rut;
            obj.provider_business_name = purchase_order?.provider_business_name;
            obj.incoterm = purchase_order?.incoterm;
            obj.destiny = purchase_order?.destiny;
            obj.payment_type = purchase_order?.payment_type;
            obj.payment_condition = purchase_order?.payment_condition;
            setServiceManifestDataForm({ ...serviceManifestDataForm, ...obj });
        } else if (name == 'purchase_order_id' && value == undefined) {
            obj.society_rut = '';
            obj.society_business_name = '';
            obj.provider_rut = '';
            obj.provider_business_name = '';
            obj.incoterm = '';
            obj.destiny = '';
            obj.payment_type = '';
            obj.payment_condition = '';
        } else {
            setServiceManifestDataForm({ ...serviceManifestDataForm, ...obj });
        }
    };

    if (!serviceManifestDataForm) return null;

    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Sociedad</label>
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={serviceManifestDataForm.society_id}
                        options={SelectTwoMapperOptions(societies, 'id', ['name'])}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedor</label>
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={serviceManifestDataForm.provider_id}
                        options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('provider_id', value.value)}
                        placeholder={'Seleccione proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>
            {purchaseOrdersLoading ? (
                <LazyLoading height="300" />
            ) : (
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">Orden de compra</label>
                        <SelectTwo
                            name="purchase_order_id"
                            id="purchase_order_id"
                            inputValue={serviceManifestDataForm.purchase_order_id}
                            options={SelectTwoMapperOptions(purchaseOrders, 'id', ['name'])}
                            hasError={fieldHasError('purchase_order_id') !== ''}
                            onChange={(value: any) =>
                                handleChangeSelectTwo('purchase_order_id', value.value)
                            }
                            placeholder={'Selec. orden de compra'}
                            onFocus={() => onFocusRemove('purchase_order_id')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('purchase_order_id')}
                        </div>
                    </div>
                </div>
            )}
            <hr />
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Rut Sociedad</label>
                    <input
                        name="society_rut"
                        id="society_rut"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.society_rut}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razón social sociedad</label>
                    <input
                        name="society_business_name"
                        id="society_business_name"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.society_business_name}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Rut Proveedor</label>
                    <input
                        name="provider_rut"
                        id="provider_rut"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.provider_rut}
                        disabled={true}
                    />
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Razón social proveedor</label>
                    <input
                        name="provider_business_name"
                        id="provider_business_name"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.provider_business_name}
                        disabled={true}
                    />
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Condición de Entrega</label>
                    <input
                        name="incoterm"
                        id="incoterm"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.incoterm}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('incoterm_id')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Destino</label>
                    <input
                        name="destiny"
                        id="destiny"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.destiny}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('destiny')}
                    </div>
                </div>
            </div>

            <div className="mb-2 row">
                <div className="col-md-6">
                    <label className="col-form-label">Forma de pago</label>
                    <input
                        name="payment_type"
                        id="payment_type"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.payment_type}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_type')}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className="col-form-label">Condición de pago</label>
                    <input
                        name="payment_condition"
                        id="payment_condition"
                        type="text"
                        className="form-control"
                        value={serviceManifestDataForm.payment_condition}
                        disabled={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('payment_condition')}
                    </div>
                </div>
            </div>

            {bill && (
                <>
                    <div className="mb-2 row">
                        <div className="col-md-12">
                            <label className="col-md-12 col-form-label">Folio</label>
                            <input
                                type="text"
                                name="folio"
                                id="folio"
                                className={`form-control ${fieldHasError('folio')}`}
                                value={serviceManifestDataForm.folio}
                                onChange={handleChange}
                                placeholder="Ingrese folio"
                                onFocus={() => onFocusRemove('folio')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('folio')}
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-md-12 col-form-label">
                                Fecha emisión de factura
                            </label>
                            <input
                                type="date"
                                name="issue_date"
                                id="issue_date"
                                className={`form-control ${fieldHasError('issue_date')}`}
                                value={serviceManifestDataForm.issue_date}
                                onChange={handleChange}
                                placeholder="Ingrese fecha"
                                onFocus={() => onFocusRemove('issue_date')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('issue_date')}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label className="col-md-12 col-form-label">Factura</label>

                            <InputButtonFile
                                name={'file'}
                                objectFile={serviceManifestDataForm.file}
                                handleChangeDocumentFile={handleChangeDocumentFile}
                                handleRemoveFile={(value: any) => handleRemoveFile('file')}
                                onFocus={() => onFocusRemove('file')}
                            />

                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('file')}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 row">
                        <div className="col-md-6">
                            <label className="col-md-12 col-form-label">Fecha de entrega</label>
                            <input
                                type="date"
                                name="delivery_date"
                                id="delivery_date"
                                className={`form-control ${fieldHasError('delivery_date')}`}
                                value={serviceManifestDataForm.delivery_date}
                                onChange={handleChange}
                                placeholder="Ingrese fecha entrega"
                                onFocus={() => onFocusRemove('delivery_date')}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('delivery_date')}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ServiceManifestForm;
