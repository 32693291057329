import React from 'react';
import { IconProps } from '../types';

export const Management: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M10 17.4142L6.70711 20.7071C6.51957 20.8946 6.26522 21 6 21H2C1.44772 21 1 20.5523 1 20V16C1 15.7348 1.10536 15.4804 1.29289 15.2929L4.58579 12L0.29289 7.70711C-0.097631 7.31658 -0.097631 6.68342 0.29289 6.29289L6.29289 0.29289C6.68342 -0.097631 7.31658 -0.097631 7.70711 0.29289L12 4.58579L16.2929 0.29289C16.6834 -0.097631 17.3166 -0.097631 17.7071 0.29289L21.7071 4.29289C22.0976 4.68342 22.0976 5.31658 21.7071 5.70711L17.4142 10L21.7071 14.2929C22.0976 14.6834 22.0976 15.3166 21.7071 15.7071L15.7071 21.7071C15.3166 22.0976 14.6834 22.0976 14.2929 21.7071L10 17.4142zM11.4142 16L12.5 17.0858L13.2929 16.2929C13.6834 15.9024 14.3166 15.9024 14.7071 16.2929C15.0976 16.6834 15.0976 17.3166 14.7071 17.7071L13.9142 18.5L15 19.5858L19.5858 15L16 11.4142L11.4142 16zM15.9142 3.5L18.5 6.08579L19.5858 5L17 2.41421L15.9142 3.5zM10.5858 6L7 2.41421L2.41421 7L3.5 8.08579L4.29289 7.29289C4.68342 6.90237 5.31658 6.90237 5.70711 7.29289C6.09763 7.68342 6.09763 8.31658 5.70711 8.70711L4.91421 9.5L6 10.5858L10.5858 6z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Management;
