import React, { useEffect, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import useRut from '../../../../hooks/useRut';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import LazyLoading from '../../../../components/LazyLoading';
import { SocietyProviderDataForm } from '../../../../app/models/SocietyProvider';
import { ProviderContact } from '../../../../app/models/ProviderContact';
import { Provider } from '../../../../app/models/Provider';

interface GeneralSocietyProps {
    societyProviderForm: SocietyProviderDataForm;
    setSocietyProviderForm: (societyproviderForm: SocietyProviderDataForm) => void;
    providers: Provider[];
    providerContacts: ProviderContact[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
    loadingProviderContact: boolean;
}

const SocietyProviderForm = ({
    societyProviderForm,
    setSocietyProviderForm,
    providers,
    providerContacts,
    errorFields,
    setErrorFields,
    loadingProviderContact
}: GeneralSocietyProps) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);


    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setSocietyProviderForm({ ...societyProviderForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        setSocietyProviderForm({ ...societyProviderForm, ...obj });
    };


    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Proveedor</label>
                    <SelectTwo
                        name="provider_id"
                        id="provider_id"
                        inputValue={societyProviderForm.provider_id}
                        options={SelectTwoMapperOptions(providers, 'id', ['name'])}
                        hasError={fieldHasError('provider_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('provider_id', value.value)
                        }
                        placeholder={'Seleccione proveedor'}
                        onFocus={() => onFocusRemove('provider_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('provider_id')}
                    </div>
                </div>
            </div>
            {
                loadingProviderContact ?
                <LazyLoading height={300} />
                :
                <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Contacto Proveedor</label>
                    <SelectTwo
                        name="business_name_contact_id"
                        id="business_name_contact_id"
                        inputValue={societyProviderForm.business_name_contact_id}
                        options={SelectTwoMapperOptions(providerContacts, 'id', ['name'])}
                        hasError={fieldHasError('business_name_contact_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('business_name_contact_id', value.value)
                        }
                        placeholder={'Seleccione contacto'}
                        onFocus={() => onFocusRemove('business_name_contact_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('business_name_contact_id')}
                    </div>
                </div>
            </div>
            }

            <div className="mb-2 row">
                <label className="col-form-label">N° cuenta contable</label>
                <div className="col-md-12">
                    <input
                        name="account_number"
                        id="account_number"
                        type="text"
                        className={`form-control ${fieldHasError('account_number')}`}
                        value={societyProviderForm.account_number}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('account_number')}
                        placeholder="Ingrese n° de cuenta"
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account_number')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocietyProviderForm;
