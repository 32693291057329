import React from 'react';
import { IconProps } from '../types';

export const Depot: React.FC<IconProps> = ({ size = 18, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            width={size}
            height={size}
            viewBox="0 0 512 512"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M255.999,0.306L0,150.668v361.026h65.909h30.417h319.347h30.417H512V150.668L255.999,0.306z M415.674,481.277 L415.674,481.277H96.326v-20.272h319.347V481.277z M415.674,430.587H96.326v-20.272h319.347V430.587z M415.674,379.897H96.326 v-20.272h319.347V379.897z M415.674,329.208H96.326v-20.272h319.347V329.208z M415.674,278.518H96.326v-20.273h319.347V278.518z M415.674,227.828L415.674,227.828H96.326v-20.272h319.347V227.828z M446.091,481.277v-20.272v-30.417v-20.272v-30.417v-20.272 v-30.417v-20.272v-30.417v-20.273v-30.417V177.14H65.909v50.689v30.417v20.273v30.417v20.272v30.417v20.272v30.417v20.272v30.417 v20.272H30.417V168.078L255.999,35.582l225.582,132.496v313.198H446.091z" />
                    </g>
                </g>
            </g>
        </svg>
    );
};
