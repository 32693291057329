import React from 'react';
import { IconProps } from '../types';

export const OriginArea: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="currentColor"
            {...rest}
        >
            <g data-name="Layer 12" id="Layer_12">
                <path d="M16,17a5,5,0,1,0-5-5A5,5,0,0,0,16,17Zm0-8a3,3,0,1,1-3,3A3,3,0,0,1,16,9Z" />

                <path d="M22.72,20.57C24.89,16.78,26,13.89,26,12A10,10,0,0,0,6,12c0,1.89,1.11,4.78,3.28,8.57C6.33,21.09,2,22.32,2,25c0,3.44,7.26,5,14,5s14-1.56,14-5C30,22.32,25.67,21.09,22.72,20.57ZM16,4a8,8,0,0,1,8,8c0,1.68-1.33,4.81-3.74,8.82-.18.3-.36.6-.55.9,0,.08-.09.15-.13.22l-.45.71a1.74,1.74,0,0,1-.11.18c-.37.58-.74,1.15-1.09,1.67l-.08.12-.45.67-.07.1c-.33.49-.64.94-.91,1.32l-.06.08-.32.46,0,.05,0-.05c-.49-.68-1.14-1.61-1.87-2.7h0c-.35-.54-.73-1.11-1.11-1.71l-.12-.19L12.44,22l-.17-.27-.42-.7-.11-.18h0C9.33,16.81,8,13.68,8,12A8,8,0,0,1,16,4ZM4,25c0-.59,1.88-1.91,6.36-2.59l0,.05.42.67.07.12.46.73,0,0c.32.51.65,1,1,1.48l0,0,.43.64.08.11.35.52.07.1.34.5.05.06L14,28C7.75,27.65,4,26,4,25ZM18,28l.34-.48.05-.08.34-.49.07-.1.34-.5.1-.14.35-.53.1-.15c.31-.46.62-.94.94-1.45l.11-.16.37-.59.16-.26.32-.52.06-.09c4.48.68,6.36,2,6.36,2.59C28,26,24.25,27.65,18,28Z" />
            </g>
        </svg>
    );
};
