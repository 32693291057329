import React from 'react';
import { IconProps } from '../types';

export const Routes: React.FC<IconProps> = ({ size = 25, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="-3 -3 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M13.3787 10.5L12.9393 10.0607C12.3536 9.4749 12.3536 8.5251 12.9393 7.9393C13.5251 7.3536 14.4749 7.3536 15.0607 7.9393L18.0607 10.9393C18.6464 11.5251 18.6464 12.4749 18.0607 13.0607L15.0607 16.0607C14.4749 16.6464 13.5251 16.6464 12.9393 16.0607C12.3536 15.4749 12.3536 14.5251 12.9393 13.9393L13.3787 13.5H4.5C2.29086 13.5 0.5 11.7091 0.5 9.5C0.5 7.2909 2.29086 5.5 4.5 5.5H9.5C10.0523 5.5 10.5 5.05228 10.5 4.5C10.5 3.94772 10.0523 3.5 9.5 3.5H2C1.17157 3.5 0.5 2.82843 0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H9.5C11.7091 0.5 13.5 2.29086 13.5 4.5C13.5 6.7091 11.7091 8.5 9.5 8.5H4.5C3.94772 8.5 3.5 8.9477 3.5 9.5C3.5 10.0523 3.94772 10.5 4.5 10.5H13.3787z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Routes;
