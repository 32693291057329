import React, { useContext, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { Brand, defaultBrandDataForm } from '../../../app/models/Brand';
import useBrandService from '../../../app/services/hooks/useBrandService';
import BrandFormContainer from './BrandFormContainer';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const BrandCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [errorGetData, setErrorGetData] = useState('');
    const [brand, setBrand] = useState<Brand>(defaultBrandDataForm);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreBrand, storeBrand } = useBrandService();

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando marca...');
        storeBrand(brand, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();

                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    if (errorGetData !== '') {
        return <div>{errorGetData}</div>;
    }

    return (
        <BrandFormContainer
            fetching={fetchingStoreBrand}
            action={store}
            cancel={cancel}
            brand={brand}
            setBrand={setBrand}
            errorFields={errorFields}
        />
    );
};

export default BrandCreate;
