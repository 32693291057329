import React from 'react';
import { IconProps } from '../types';

export const Dispatch: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M7.76316 3.03209L8.62921 2.53208L9.12919 3.39807C9.40533 3.87636 10.0169 4.04023 10.4952 3.76409C10.9735 3.48795 11.1374 2.87636 10.8612 2.39807L10.3613 1.53208L11.2273 1.03209C11.7056 0.755948 12.3171 0.919824 12.5933 1.39812L14.5933 4.86222C14.8694 5.34051 14.7056 5.9521 14.2273 6.22824L10.7632 8.22824C10.2849 8.50438 9.67327 8.34051 9.39713 7.86222L7.39713 4.39812C7.12099 3.91982 7.28486 3.30823 7.76316 3.03209Z"
                fill="#758CA3"
            />

            <path
                d="M0.00874188 2.18588C0.0816363 1.63843 0.584527 1.25373 1.13198 1.32662L3.00937 1.5766C3.31703 1.61756 3.58823 1.79906 3.74341 2.06785L12.3344 16.9479C13.4804 16.8563 14.6306 17.3351 15.3664 18.2722L22.3253 14.2545C22.8036 13.9783 23.4152 14.1422 23.6914 14.6205C23.9675 15.0988 23.8036 15.7104 23.3253 15.9865L16.1054 20.1549C16.2101 21.4598 15.5751 22.7704 14.366 23.4685C12.692 24.435 10.5514 23.8614 9.58491 22.1874C8.70313 20.6601 9.10325 18.7445 10.4514 17.6865L2.25748 3.49413L0.868006 3.30912C0.320553 3.23623 -0.0641526 2.73334 0.00874188 2.18588ZM11.4763 19.4616C11.0742 19.9312 10.9902 20.6215 11.317 21.1874C11.368 21.2758 11.4267 21.3571 11.4917 21.4308C11.9468 21.9469 12.7118 22.0949 13.3354 21.7536C13.3456 21.748 13.3558 21.7423 13.366 21.7365C14.0834 21.3222 14.3293 20.4049 13.915 19.6874C13.5008 18.97 12.5834 18.7242 11.866 19.1384C11.7145 19.2259 11.584 19.3358 11.4763 19.4616Z"
                fill="#758CA3"
            />

            <path
                d="M11.7632 9.96019L13.4952 8.9602L13.9952 9.82617C14.2713 10.3045 14.8829 10.4683 15.3612 10.1922C15.8395 9.91605 16.0033 9.30446 15.7272 8.82617L15.2272 7.9602L16.9593 6.96019C17.4376 6.68405 18.0492 6.84792 18.3253 7.32622L20.8253 11.6563C21.1015 12.1346 20.9376 12.7462 20.4593 13.0224L15.2632 16.0224C14.7849 16.2985 14.1733 16.1346 13.8971 15.6563L11.3971 11.3262C11.121 10.8479 11.2849 10.2363 11.7632 9.96019Z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Dispatch;
