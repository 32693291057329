import React from 'react';
import { IconProps } from '../types';

export const PackingForm: React.FC<IconProps> = ({ size = 22, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={size}
            height={size}
            viewBox="0 0 295.097 295.097"
            fill="currentColor"
            {...rest}
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />

            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

            <g id="SVGRepo_iconCarrier">
                {' '}
                <g>
                    {' '}
                    <g>
                        <path d="M272.596,156.923h-82.861h-9.375H22.5L0,213.173h20.986v79.688h159.375h9.375h84.375v-79.688 h20.986L272.596,156.923z M13.847,203.798l15-37.5H178.13l-15,37.5H13.847z M180.361,283.486h-150v-70.313h139.111l10.889-27.225 V283.486z M264.735,283.486h-75v-97.537l10.889,27.225h64.111V283.486z M206.972,203.798l-15-37.5h74.283l15,37.5H206.972z" />{' '}
                        <path d="M147.548,147.548L95.986,105.36h23.437V2.236h56.25v103.125h23.437L147.548,147.548z" />{' '}
                    </g>{' '}
                </g>{' '}
            </g>
        </svg>
    );
};
