import React, { useContext, useEffect, useState } from 'react';

import { Hopper, HopperFilters } from '../../app/models/Hopper';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import useHopperService from '../../app/services/hooks/useHopperService';
import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useSweetAlert from '../../hooks/useSweetAlert';
import { toast } from 'react-toastify';
import HopperCreate from './components/HopperCreate';
import HopperEdit from './components/HopperEdit';
import { AppContext } from '../../contexts/AppContext';
import HopperDataTable from './components/HopperDataTable';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Lista diaria de transportistas',
        url: '/daily-carriers',
        isActive: true
    }
];

const Hoppers = () => {
    const { fetchingGetHoppers, getHoppers, deleteHopper } = useHopperService();
    const [hoppers, setHoppers] = useState<Hopper[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [hopperIdEdit, setHopperIdEdit] = useState<number>(-1);
    const [hopperIdShow, setHopperIdShow] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingShow, setShowingShow] = useState(false);
    const [showingCreate, setShowingCreate] = useState(false);
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [filter, setFilter] = useState<HopperFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllHoppers();
    }, [filter]);

    const getAllHoppers = () => {
        getHoppers(filter, {
            onSuccess: (response: ServiceResponse) => {
                setHoppers(response.data.hoppers);
                setTotalRows(response.data.total_rows);
            }
        });
    };

    const showEdit = (HopperId: number) => {
        setShowingEdit(true);
        setHopperIdEdit(HopperId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setHopperIdEdit(-1);
    };

    const showShow = (HopperId: number) => {
        setShowingShow(true);
        setHopperIdShow(HopperId);
    };

    const hideShow = () => {
        setShowingShow(false);
        setHopperIdShow(-1);
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };

    const reloadTable = () => {
        getAllHoppers();
    };

    const destroy = (HopperId: number) => {
        const _text = 'Está a punto de eliminar el Tolva #' + HopperId;

        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                deleteHopper(HopperId, {
                    onSuccess: () => {
                        useSweetAlert().successAlert({
                            title: 'Éxito',
                            text: 'Se ha eliminado el Tolva #' + HopperId
                        });
                        reloadTable();
                    },
                    onError: () => {
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'No se ha podido el Tolva #' + HopperId
                        });
                    }
                });
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El Tolva no se ha eliminado.'
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Tolvas" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                <DefaultToolBar
                    left={<ButtonCreate callbackCreate={showCreate} title="Nueva Tolva" />}
                />
                {/* <HopperTable
                    hoppers={Hoppers}
                    edit={showEdit}
                    destroy={destroy}
                    loading={fetchingGetHoppers}
                /> */}
                <HopperDataTable
                    hoppers={hoppers}
                    totalRows={totalRows}
                    loading={fetchingGetHoppers}
                    destroy={destroy}
                    edit={showEdit}
                    filter={filter}
                    setFilter={setFilter}
                />
            </DefaultCard>

            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Tolva"
                    backdrop={true}
                    showFooter={false}
                >
                    <HopperCreate
                        onSaved={() => {
                            toast.success('Tolva Registrado');
                            reloadTable();
                            hideCreate();
                        }}
                        onError={(message: string) => {
                            toast.error(message);
                        }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Editar Tolva"
                    backdrop={true}
                    showFooter={false}
                >
                    <HopperEdit
                        hopperId={hopperIdEdit}
                        onSaved={() => {
                            toast.success('Tolva Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        onError={(message: string) => {
                            toast.error(message);
                        }}
                        onCancel={hideEdit}
                    />
                </DefaultModal>
            ) : null}

            {showingShow ? (
                <DefaultModal
                    show={showingShow}
                    handleClose={hideEdit}
                    title="Editar Tolva"
                    backdrop={true}
                    showFooter={false}
                >
                    <HopperEdit
                        hopperId={hopperIdEdit}
                        onSaved={() => {
                            toast.success('Tolva Actualizado');
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default Hoppers;
