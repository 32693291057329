import React from 'react';
import { IconProps } from '../types';

export const ProviderTypes: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M3 8V1C3 0.44772 3.44772 0 4 0H11C11.3921 0 11.7481 0.2292 11.9104 0.5862L16.6439 11H19V3C19 2.44772 19.4477 2 20 2C20.5523 2 21 2.44772 21 3V19H23C23.5523 19 24 19.4477 24 20C24 20.5523 23.5523 21 23 21H20C19.4477 21 19 20.5523 19 20V19H16.9646C16.7219 20.6961 15.2632 22 13.5 22C11.7368 22 10.2781 20.6961 10.0354 19H6.96456C6.72194 20.6961 5.26324 22 3.5 22C1.567 22 0 20.433 0 18.5C0 18.3899 0.00507963 18.2811 0.0150172 18.1736C0.00514672 18.1172 0 18.0592 0 18V9C0 8.44771 0.447715 8 1 8H3zM14.447 11L10.3561 2H5V8H6C6.26522 8 6.51957 8.10536 6.70711 8.29289L9.41421 11H14.447zM17 13V17H19V13H17zM3.5 20C4.32843 20 5 19.3284 5 18.5C5 17.6716 4.32843 17 3.5 17C2.67157 17 2 17.6716 2 18.5C2 19.3284 2.67157 20 3.5 20zM13.5 20C14.3284 20 15 19.3284 15 18.5C15 17.6716 14.3284 17 13.5 17C12.6716 17 12 17.6716 12 18.5C12 19.3284 12.6716 20 13.5 20z"
                fill="#758CA3"
            />{' '}
        </svg>
    );
};

export default ProviderTypes;
