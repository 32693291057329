import React, { useContext, useEffect, useRef, useState } from 'react';
import { Sidebar, Menu, MenuItem, MenuItemStyles } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import MenuTop from './MenuTop';
import LazyLoading from '../../../components/LazyLoading';
import { getMenuItemStyles, hexToRgba, themes } from './Sidebar/utils/getTheme';
import { ITEMS, MENU_ITEMS, verify_path } from './Sidebar/utils/getPermissions';
import { AuthContext } from '../../../contexts/AuthContext';
import { AppContext } from '../../../contexts/AppContext';
import SubMenuComponent from '../components/SubMenuComponent';
import { Roles } from '../../../app/shared/enums';
import useAuthService from '../../../app/services/hooks/useAuthService';
import { Dashboard } from '../../../template/MainTheme/layouts/Sidebar/icons/Dashboard';
import { Sales } from '../../../template/MainTheme/layouts/Sidebar/icons/sales/Sales';
import { Quotes } from '../../../template/MainTheme/layouts/Sidebar/icons/sales/Quotes';
import { Orders } from '../../../template/MainTheme/layouts/Sidebar/icons/sales/Orders';
import { Logistics } from '../../../template/MainTheme/layouts/Sidebar/icons/logistics/Logistics';
import { Dispatch } from '../../../template/MainTheme/layouts/Sidebar/icons/logistics/Dispatch';
import { Carriers } from '../../../template/MainTheme/layouts/Sidebar/icons/logistics/Carriers';
import { Provider } from './Sidebar/icons/purchases/Provider';
import { PaymentStatus } from '../../../template/MainTheme/layouts/Sidebar/icons/purchases/PaymentStatus';
import { Purchases } from '../../../template/MainTheme/layouts/Sidebar/icons/purchases/Purchases';
import { Management } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Management';
import { Carrier } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Carriers';
import { Clients } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Clients';
import { Hoppers } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Hoppers';
import { Places } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Places';
import { Providers } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Providers';
import { ProviderTypes } from '../../../template/MainTheme/layouts/Sidebar/icons/management/ProviderTypes';
import { Routes } from '../../../template/MainTheme/layouts/Sidebar/icons/management/Routes';
import { Sector } from './Sidebar/icons/sales/Sector';
import { PackingForm } from './Sidebar/icons/sales/PackingForm';
import { OriginArea } from './Sidebar/icons/sales/OriginArea';
import { Service } from './Sidebar/icons/Service';
import { Product } from './Sidebar/icons/sales/Product';
import { Currency } from './Sidebar/icons/management/Currency';
import { Unit } from './Sidebar/icons/management/Unit';
import User from '../../../pages/Users';
import Users from '../../../pages/Users';
import { Depot } from './Sidebar/icons/management/Depot';
import { Center } from './Sidebar/icons/management/Center';
import { AccountType } from './Sidebar/icons/management/AccountType';
import { CheckingAccount } from './Sidebar/icons/management/CheckingAccount';
import { Bank } from './Sidebar/icons/management/Bank';

type Theme = 'light' | 'dark';

const SideBarPro = () => {
    const location = useLocation();
    const { showingMainMenu, showMainMenu, hideMainMenu } = useContext(AppContext);
    const [collapsed, setCollapsed] = React.useState(false);
    const [menuItemStyles, setMenuItemStyles] = React.useState<MenuItemStyles>();
    const [broken, setBroken] = React.useState(false);
    const { getRoles } = useAuthService();
    const [rtl, setRtl] = React.useState(false);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState<string[]>([]);
    const [theme, setTheme] = React.useState<Theme>('dark');
    const { updateAuth, auth } = useContext(AuthContext);

    useEffect(() => {
        setMenuItemStyles(getMenuItemStyles(theme, collapsed));
    }, [theme, collapsed]);

    const onBackdropClick = () => {
        console.log('click atras para cerrar');
        if (hideMainMenu) hideMainMenu();
    };

    useEffect(() => {
        getRoles({
            onSuccess: (response) => {
                setRoles(response.data.roles);
                if (auth && updateAuth) {
                    updateAuth({
                        ...auth,
                        roles: response.data.roles
                    });
                }
            }
        });
    }, []);

    useEffect(() => {
        if (broken) {
            onBackdropClick();
            setCollapsed(false);
        } else {
            if (showMainMenu) showMainMenu();
        }
    }, [broken]);

    useEffect(() => {
        const body = document.body;
        // Toggle 'vertical-collapsed' based on the value of 'collapsed'
        body.classList.toggle('vertical-collapsed', collapsed);

        // Toggle 'sidebar-enable' based on the negation of 'collapsed'
        body.classList.toggle('sidebar-enable', !collapsed);
    }, [collapsed]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [collapsed]);

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    display: 'flex',
                    // height: '100vh',
                    top: 70,
                    bottom: 0,
                    zIndex: 1000
                }}
            >
                <MenuTop />
                <Sidebar
                    collapsed={collapsed}
                    toggled={showingMainMenu}
                    onBackdropClick={hideMainMenu}
                    onBreakPoint={setBroken}
                    image="https://user-images.githubusercontent.com/25878302/144499035-2911184c-76d3-4611-86e7-bc4e8ff84ff5.jpg"
                    rtl={rtl}
                    breakPoint="md"
                    backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, 1)}
                    rootStyles={{
                        color: themes[theme].sidebar.color
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flex: 1, marginBottom: '32px' }}>
                            {loading ? (
                                <LazyLoading height="300" />
                            ) : (
                                <>
                                    {!broken && (
                                        <div
                                            style={{
                                                padding: '0 18px',
                                                marginBottom: '0px',
                                                marginTop: '0px',
                                                textAlign: 'left'
                                            }}
                                        >
                                            <button
                                                type="button"
                                                style={{ height: '50px' }}
                                                onClick={() => {
                                                    setCollapsed(!collapsed);
                                                }}
                                                className="btn btn-sm font-size-16 header-item "
                                                id="vertical-menu-btn"
                                            >
                                                <i className="fa fa-fw fa-bars text-white" />
                                            </button>
                                        </div>
                                    )}
                                    <Menu menuItemStyles={menuItemStyles}>
                                        <>
                                            <MenuItem
                                                active={location.pathname === ITEMS.dashboard.to}
                                                icon={<Dashboard />}
                                                component={<Link to={ITEMS.dashboard.to} />}
                                            >
                                                Dashboard
                                            </MenuItem>
                                            {roles.includes(Roles.ADMIN) ||
                                            roles.includes(Roles.MANAGERS) ||
                                            roles.includes(Roles.OPERATIONS_MANAGER) ? (
                                                // VENTAS
                                                <>
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.sales_single_items.paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        icon={<Sales />}
                                                        label="Ventas y Despachos"
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.quotes.to ||
                                                                    location.pathname.includes(
                                                                        ITEMS.quotes.to
                                                                    )
                                                                }
                                                                icon={<Quotes />}
                                                                component={
                                                                    <Link to={ITEMS.quotes.to} />
                                                                }
                                                            >
                                                                Cotizaciones
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.transportation_orders.to ||
                                                                    location.pathname.includes(
                                                                        ITEMS.transportation_orders.to
                                                                    )
                                                                }
                                                                icon={<Orders />}
                                                                component={
                                                                    <Link
                                                                        to={
                                                                            ITEMS
                                                                                .transportation_orders
                                                                                .to
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                Pedidos
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.dispatch_programs.to ||
                                                                    location.pathname.includes(
                                                                        ITEMS
                                                                            .dispatch_programs.to
                                                                            
                                                                    )
                                                                }
                                                                icon={<Dispatch />}
                                                                component={
                                                                    <Link
                                                                        to={
                                                                            ITEMS.dispatch_programs
                                                                                .to
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                Despachos
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.payment_status.to
                                                                }
                                                                icon={<PaymentStatus />}
                                                                component={
                                                                    <Link
                                                                        to={ITEMS.payment_status.to}
                                                                    />
                                                                }
                                                            >
                                                                Estados de pago
                                                            </MenuItem>
                                                        </>
                                                    </SubMenuComponent>
                                                </>
                                            ) : null}
                                            {/* LOGISTICA */}
                                            {roles.includes(Roles.ADMIN) ||
                                            roles.includes(Roles.MANAGERS) ||
                                            roles.includes(Roles.OPERATIONS_MANAGER) ||
                                            roles.includes(Roles.RUNNER) ? (
                                                <>
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.logistics_single_items
                                                                .paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        icon={<Logistics />}
                                                        label="Logistic"
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            
                                                            <>
                                                                {roles.includes(Roles.MANAGERS) ||
                                                                roles.includes(Roles.RUNNER) ? (
                                                                    <MenuItem
                                                                        active={
                                                                            location.pathname ===
                                                                            ITEMS.daily_carriers.to
                                                                        }
                                                                        icon={<Carriers />}
                                                                        component={
                                                                            <Link
                                                                                to={
                                                                                    ITEMS
                                                                                        .daily_carriers
                                                                                        .to
                                                                                }
                                                                            />
                                                                        }
                                                                    >
                                                                        Transportes
                                                                    </MenuItem>
                                                                ) : null}
                                                            </>
                                                        </>
                                                    </SubMenuComponent>
                                                </>
                                            ) : null}
                                            {/* COMPRAS */}
                                            {roles.includes(Roles.ADMIN) ||
                                            roles.includes(Roles.MANAGERS) ||
                                            roles.includes(Roles.OPERATIONS_MANAGER) ||
                                            roles.includes(Roles.RUNNER) ? (
                                                <>
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.purchases_single_items
                                                                .paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        icon={<Purchases />}
                                                        label="Compras"
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.purchase_order.to ||
                                                                        location.pathname.includes(
                                                                            ITEMS.purchase_order.to 
                                                                                
                                                                        )
                                                                    }
                                                                    icon={<Provider />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.purchase_order.to}
                                                                        />
                                                                    }
                                                                >
                                                                    Ordenes de compra
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                        ITEMS.service_manifests
                                                                            .to ||
                                                                    location.pathname.includes(
                                                                        ITEMS.service_manifests
                                                                            .to
                                                                    )
                                                                }
                                                                icon={<Provider />}
                                                                component={
                                                                    <Link
                                                                        to={
                                                                            ITEMS
                                                                                .service_manifests
                                                                                .to
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                Hojas de entrada
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.providers.to
                                                                }
                                                                icon={<Providers />}
                                                                component={
                                                                    <Link
                                                                        to={
                                                                            ITEMS.providers
                                                                                .to
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                Proveedores
                                                            </MenuItem>
                                                        
                                                        </>
                                                    </SubMenuComponent>
                                                </>
                                            ) : null}
                                            {/* CUENTAS CORRIENTES */}
                                            {roles.includes(Roles.ADMIN) ||
                                            roles.includes(Roles.MANAGERS) ||
                                            roles.includes(Roles.OPERATIONS_MANAGER) ||
                                            roles.includes(Roles.RUNNER) ? (
                                                <>
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS
                                                                .checking_account_single_items.paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        label="Cuentas Corrientes"
                                                        icon={<CheckingAccount />}
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.checking_account.to
                                                                }
                                                                icon={<CheckingAccount />}
                                                                component={
                                                                    <Link 
                                                                        to={ITEMS.checking_account.to}
                                                                    />
                                                                }
                                                            >
                                                                Transferencias
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.internal_settlement.to
                                                                }
                                                                icon={<CheckingAccount />}
                                                                component={
                                                                    <Link
                                                                        to={
                                                                            ITEMS
                                                                                .internal_settlement
                                                                                .to
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                Cuentas
                                                            </MenuItem>

                                                        
                                                        </>
                                                    </SubMenuComponent>
                                                </>

                                            ): null}
                                            {/* GESTION */}
                                            {roles.includes(Roles.ADMIN) ||
                                            roles.includes(Roles.MANAGERS) ||
                                            roles.includes(Roles.OPERATIONS_MANAGER) ? (
                                                <>
                                                    <SubMenuComponent
                                                        pathList={[
                                                            ...MENU_ITEMS.management_single_items
                                                                .paths
                                                        ]}
                                                        pathName={location.pathname}
                                                        icon={<Management />}
                                                        label="Gestión"
                                                        collapsed={collapsed}
                                                        showingMainMenu={showingMainMenu}
                                                    >
                                                        <>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.clients.to
                                                                }
                                                                icon={<Clients />}
                                                                component={
                                                                    <Link to={ITEMS.clients.to} />
                                                                }
                                                            >
                                                                Clientes
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.contact_types.to
                                                                }
                                                                icon={<Clients />}
                                                                component={
                                                                    <Link to={ITEMS.contact_types.to} />
                                                                }
                                                            >
                                                                Tipo de Contacto
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.users.to
                                                                }
                                                                icon={<Clients />}
                                                                component={
                                                                    <Link to={ITEMS.users.to} />
                                                                }
                                                            >
                                                                Usuarios
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.places.to
                                                                }
                                                                icon={<Places />}
                                                                component={
                                                                    <Link to={ITEMS.places.to} />
                                                                }
                                                            >
                                                                Origenes & Destinos
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.society.to
                                                                    }
                                                                    icon={<Clients />}
                                                                    component={
                                                                        <Link to={ITEMS.society.to} />
                                                                    }
                                                                >
                                                                    Sociedades
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.society_signatures.to
                                                                    }
                                                                    icon={<Clients />}
                                                                    component={
                                                                        <Link to={ITEMS.society_signatures.to} />
                                                                    }
                                                                >
                                                                    Firmas de Sociedades
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.products.to
                                                                    }
                                                                    icon={<Product />}
                                                                    component={
                                                                        <Link to={ITEMS.products.to} />
                                                                    }
                                                                >
                                                                    Productos
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.taxes.to
                                                                    }
                                                                    icon={<Sector />}
                                                                    component={
                                                                        <Link to={ITEMS.taxes.to} />
                                                                    }
                                                                >
                                                                    Impuestos
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.currency.to   
                                                                    }
                                                                    icon={<Currency />}
                                                                    component={
                                                                        <Link to={ITEMS.currency.to} />
                                                                    }
                                                                >
                                                                    Monedas
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.foreing_exchange.to  
                                                                    }
                                                                    icon={<Currency />}
                                                                    component={
                                                                        <Link to={ITEMS.foreing_exchange.to } />
                                                                    }
                                                                >
                                                                    Divisas
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.unit.to   
                                                                    }
                                                                    icon={<Unit />}
                                                                    component={
                                                                        <Link to={ITEMS.unit.to} />
                                                                    }
                                                                >
                                                                    Unidades
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.item_categories.to
                                                                    }
                                                                    icon={<Service />}
                                                                    component={
                                                                        <Link to={ITEMS.item_categories.to}/>
                                                                    }
                                                                >
                                                                    Categorías
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.accounts.to
                                                                    }
                                                                    icon={<AccountType />}
                                                                    component={
                                                                        <Link to={ITEMS.accounts.to} />
                                                                    }
                                                                >
                                                                    Cuentas Contables
                                                             </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.clasifications.to
                                                                    }
                                                                    icon={<AccountType />}
                                                                    component={
                                                                        <Link to={ITEMS.clasifications.to} />
                                                                    }
                                                                >
                                                                    Clasificaciones
                                                            </MenuItem>
                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.services.to
                                                                    }
                                                                    icon={<Service />}
                                                                    component={
                                                                        <Link to={ITEMS.services.to}/>
                                                                    }
                                                                >
                                                                    Servicios
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.incoterms.to
                                                                    }
                                                                    icon={<Sector />}
                                                                    component={
                                                                        <Link to={ITEMS.incoterms.to} />
                                                                    }
                                                                >
                                                                    Condiciones de entrega
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.sectors.to
                                                                    }
                                                                    icon={<Sector />}
                                                                    component={
                                                                        <Link to={ITEMS.sectors.to} />
                                                                    }
                                                                >
                                                                    Sectores
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.packing_forms.to
                                                                    }
                                                                    icon={<PackingForm />}
                                                                    component={
                                                                        <Link to={ITEMS.packing_forms.to} />
                                                                    }
                                                                >
                                                                    Formas de empaque
                                                            </MenuItem>

                                                            <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.origin_areas.to
                                                                    }
                                                                    icon={<OriginArea />}
                                                                    component={
                                                                        <Link to={ITEMS.origin_areas.to} />
                                                                    }
                                                                >
                                                                    Zonas de origen
                                                            </MenuItem>
                                                            
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.provider_types.to
                                                                }
                                                                icon={<ProviderTypes />}
                                                                component={
                                                                    <Link
                                                                        to={ITEMS.provider_types.to}
                                                                    />
                                                                }
                                                            >
                                                                Tipos de Proveedores
                                                            </MenuItem>
                                                            <>
                                                                {roles.includes(Roles.MANAGERS) ||
                                                                roles.includes(Roles.RUNNER) ? (
                                                                    <>
                                                                        <MenuItem
                                                                            active={
                                                                                location.pathname ===
                                                                                ITEMS.carriers.to
                                                                            }
                                                                            icon={<Carrier />}
                                                                            component={
                                                                                <Link
                                                                                    to={
                                                                                        ITEMS
                                                                                            .carriers
                                                                                            .to
                                                                                    }
                                                                                />
                                                                            }
                                                                        >
                                                                            Conductores
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            active={
                                                                                location.pathname ===
                                                                                ITEMS.trucks.to
                                                                            }
                                                                            icon={<Carriers />}
                                                                            component={
                                                                                <Link
                                                                                    to={
                                                                                        ITEMS.trucks
                                                                                            .to
                                                                                    }
                                                                                />
                                                                            }
                                                                        >
                                                                            Camiones
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            active={
                                                                                location.pathname ===
                                                                                ITEMS.hoppers.to
                                                                            }
                                                                            icon={<Hoppers />}
                                                                            component={
                                                                                <Link
                                                                                    to={
                                                                                        ITEMS
                                                                                            .hoppers
                                                                                            .to
                                                                                    }
                                                                                />
                                                                            }
                                                                        >
                                                                            Tolvas
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            active={
                                                                                location.pathname ===
                                                                                ITEMS.route_segments
                                                                                    .to
                                                                            }
                                                                            icon={<Routes />}
                                                                            component={
                                                                                <Link
                                                                                    to={
                                                                                        ITEMS
                                                                                            .route_segments
                                                                                            .to
                                                                                    }
                                                                                />
                                                                            }
                                                                        >
                                                                            Tramos
                                                                        </MenuItem>
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.bank.to
                                                                    }
                                                                    icon={<Bank />}
                                                                    component={<Link to={ITEMS.bank.to} />}
                                                                >
                                                                    Bancos
                                                                </MenuItem>
                                                                        <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.bank_account_reason.to
                                                                    }
                                                                    icon={<Bank />}
                                                                    component={<Link to={ITEMS.bank_account_reason.to} />}
                                                                >
                                                                    Motivos de cuenta bancaria
                                                                </MenuItem>
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname === ITEMS.account_type.to
                                                                        
                                                                    }
                                                                    icon={<AccountType />}
                                                                    component={
                                                                        <Link to= {ITEMS.account_type.to} />
                                                                    }
                                                                >
                                                                    Tipo de cuentas
                                                                </MenuItem>
                                                                <MenuItem
                                                                    active={
                                                                        location.pathname ===
                                                                        ITEMS.batches.to ||
                                                                        location.pathname.includes(
                                                                            ITEMS.batches.to
                                                                        )
                                                                    }
                                                                    icon={<PaymentStatus />}
                                                                    component={
                                                                        <Link
                                                                            to={ITEMS.batches.to}
                                                                        />
                                                                    }
                                                            >
                                                                Lotes
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.batch_types.to
                                                                }
                                                                icon={<PaymentStatus />}
                                                                component={
                                                                    <Link
                                                                        to={ITEMS.batch_types.to}
                                                                    />
                                                                }
                                                            >
                                                                Tipo de lotes
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.depots.to
                                                                }
                                                                icon={<Depot />}
                                                                component={
                                                                    <Link to={ITEMS.depots.to} />
                                                                }
                                                            >
                                                                Almacenes
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.brands.to
                                                                }
                                                                icon={<Sector />}
                                                                component={
                                                                    <Link to={ITEMS.brands.to} />
                                                                }
                                                            >
                                                                Marcas lote
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.centers.to
                                                                }
                                                                icon={<Center />}
                                                                component={
                                                                    <Link
                                                                        to={ITEMS.centers.to}
                                                                    />
                                                                }
                                                            >
                                                                Centros
                                                            </MenuItem>
                                                            <MenuItem
                                                                active={
                                                                    location.pathname ===
                                                                    ITEMS.business_names.to
                                                                }
                                                                icon={<AccountType />}
                                                                component={
                                                                    <Link to={ITEMS.business_names.to} />
                                                                }
                                                            >
                                                                Razones Sociales
                                                             </MenuItem>
                                                            </>
                                                                ) : null}
                                                            </>
                                                        </>
                                                    </SubMenuComponent>
                                                </>
                                            ) : null}
                                        </>
                                    </Menu>
                                </>
                            )}
                        </div>
                    </div>
                </Sidebar>
            </div>
        </>
    );
};

export default SideBarPro;
