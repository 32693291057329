import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import { PaymentCondition } from '../../../app/models/PaymentCondition';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import { Currency } from '../../../app/models/Currency';
import {
    defaultDispatchProgram,
    defaultDispatchProgramShowDataForm,
    DispatchProgram,
    DispatchProgramShowDataForm
} from '../../../app/models/DispatchProgram';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCard from '../../../components/default/DefaultCard';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { toast } from 'react-toastify';
import DispatchProgramForm from '../components/forms/DispatchProgramForm';
import { TransportationOrderDataForm } from '../../../app/models/TransportationOrder';
import DispatchProgramShowForm from '../components/forms/DispatchProgramShowForm';
import DispatchProgramItemsContainer from '../components/DispatchProgramItemsContainer';
import { RouterParams } from '../../../app/shared/interfaces';
import { RunnerDataForm } from '../../../app/models/Runner';
import { AuthContext } from '../../../contexts/AuthContext';
import { Roles } from '../../../app/shared/enums';
import { QuoteReferenceDataForm } from '../../../app/models/Quote';
import useSweetAlert from '../../../hooks/useSweetAlert';

const DispatchProgramEdit = () => {
    const { id } = useParams<RouterParams>();

    const { auth } = useContext(AuthContext);

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Despachos',
            url: '/dispatch-programs',
            isActive: false
        },
        {
            name: 'Editar despacho #' + id,
            url: '/dispatch-programs/' + id + '/edit',
            isActive: true
        }
    ];

    const {
        editDispatchProgram,
        fetchingEditDispatchProgram,
        fetchingShowDispatchProgram,
        fetchingShowTransportationOrderDispatchProgram,
        updateDispatchProgram,
        showDispatchProgram,
        showTransportationOrderDispatchProgram
    } = useDispatchProgramService();

    const [dispatchProgramForm, setDispatchProgramForm] = useState<DispatchProgram>({
        contact_email: '',
        client_id: 0,
        // payment_method_id: 0,
        // payment_condition_id: null,
        // currency_id: 0,
        society_id: 0
    });
    const [dispatchProgramShowDataForm, setDispatchProgramShowDataForm] =
        useState<DispatchProgramShowDataForm>(defaultDispatchProgramShowDataForm);

    const [errorFields, setErrorFields] = useState<any>();
    const [canBeEdited, setCanBeEdited] = useState(false);

    const [transportationOrders, setTransportationOrders] = useState<TransportationOrderDataForm[]>(
        []
    );
    const [clients, setClients] = useState<Client[]>([]);
    const [societies, setSocieties] = useState<Society[]>([]);
    // const [currencies, setCurrencies] = useState<Currency[]>([]);
    // const [paymentConditions, setPaymentsConditions] = useState<PaymentCondition[]>([]);
    // const [paymentMethods, setPaymentMethods] = useState<PaymentCondition[]>([]);
    const [runners, setRunners] = useState<RunnerDataForm[]>([]);
    // const [references, setReferences] = useState<QuoteReferenceDataForm[]>([]);
    // const [condition, setCondition] = useState<boolean>(false);
    const [availableUse, setAvailableUse] = useState<boolean>(false);
    const [transportationSubOrderItemId, setTransportationSubOrderItemId] = useState<number>(0);
    const [isNotAuthorized, setIsNotAuthorized] = useState(false);

    const dispatchProgramFromToi = useRef<DispatchProgram|undefined>();
    const clientsFromToi = useRef<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);

    useEffect(() => {
        if (
            auth?.roles?.includes(Roles.ADMIN) ||
            auth?.roles?.includes(Roles.MANAGERS) ||
            auth?.roles?.includes(Roles.OPERATIONS_MANAGER)
        ) {
            setAvailableUse(false);
        } else {
            setAvailableUse(true);
        }
    });

    useEffect(() => {
        reloadModule();
    }, []);

    const reloadModule = () => {
        edit(parseInt(id));
        // show(parseInt(id));
    };

    const edit = (id: number) => {
        setCanBeEdited(false);
        editDispatchProgram(id, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationSubOrderItemId(
                    response.data.dispatch_program.transportation_order_item_id
                );
                if (
                    response.data.dispatch_program.transportation_order_id != '' ||
                    response.data.dispatch_program.transportation_order_id != null
                ) {
                    setCanBeEdited(true);
                }
                if (
                    auth?.roles?.includes(Roles.RUNNER) &&
                    auth?.roles?.length == 1 &&
                    response.data.dispatch_program.runner_id != auth?.id
                ) {
                    // setIsNotAuthorized(true);
                }

                setSocieties(response.data.societies);
                setClients(response.data.clients);

                setTransportationOrders(response.data.transportation_orders);
                setRunners(response.data.runners);
                societyIdSelected.current = response.data.dispatch_program.society_id;
                setDispatchProgramForm(response.data.dispatch_program);
                
                clientsFromToi.current = response.data.clients;
                dispatchProgramFromToi.current = response.data.dispatch_program;
            }
        });
    };

    const show = (id: number) => {
        showDispatchProgram(id, {
            onSuccess: (response: ServiceResponse) => {
                setDispatchProgramShowDataForm(response.data.dispatch_program);
            }
        });
    };

    const update = () => {
        useSweetAlert().spinnerAlert('Actualizando despacho..');
        updateDispatchProgram(dispatchProgramForm.id!, dispatchProgramForm, {
            onSuccess: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.success(response.message);

                setErrorFields(undefined);
                reloadModule();
            },
            onError: (response: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                useSweetAlert().closeSpinnerAlert();
                setErrorFields(errorFields.data);
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection={`Editar despacho #${id}`} breadcrumbs={breadcrumbs} />
            {isNotAuthorized ? (
                <div className="alert alert-danger">
                    No tienes permisos para editar este despacho
                </div>
            ) : (
                <>
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {!fetchingEditDispatchProgram ? (
                                    canBeEdited ? (
                                        <>
                                            <DispatchProgramForm
                                                dispatchProgramForm={dispatchProgramForm}
                                                setDispatchProgramForm={setDispatchProgramForm}
                                                societies={societies}
                                                clients={clients}
                                                runners={runners}
                                                errorFields={errorFields}
                                                transportationOrders={transportationOrders}
                                                disabled={true}
                                                availableUse={availableUse}
                                            />
                                            <div className="row justify-content-end">
                                                <div className="col-auto">
                                                    <ButtonSaveForm callbackSave={update} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <DispatchProgramShowForm
                                            dispatchProgramShowDataForm={
                                                dispatchProgramShowDataForm
                                            }
                                            transportationOrder={null}
                                        />
                                    )
                                ) : (
                                    <LazyLoading />
                                )}
                            </DefaultCard>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                                    {!fetchingEditDispatchProgram ? (
                                        canBeEdited ? (
                                            <>
                                                <DefaultCreatedModifiedUserForm
                                                    createdBy={dispatchProgramForm.created_user}
                                                    createdAt={dispatchProgramForm.created_at}
                                                    updatedBy={
                                                        dispatchProgramForm.last_modified_user
                                                    }
                                                    updatedAt={dispatchProgramForm.updated_at}
                                                    status={dispatchProgramForm.status}
                                                />
                                            </>
                                        ) : (
                                            <DefaultCreatedModifiedUserForm
                                                createdBy={dispatchProgramShowDataForm.created_user}
                                                createdAt={dispatchProgramShowDataForm.created_at}
                                                updatedBy={
                                                    dispatchProgramShowDataForm.last_modified_user
                                                }
                                                updatedAt={dispatchProgramShowDataForm.updated_at}
                                                status={dispatchProgramForm.status}
                                                // status={
                                                //     dispatchProgramShowDataForm.status_selected
                                                //         ? dispatchProgramShowDataForm.status_selected.name.toString()
                                                //         : ''
                                                // }
                                            />
                                        )
                                    ) : (
                                        <LazyLoading />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {!fetchingEditDispatchProgram ? (
                        <>
                            <DispatchProgramItemsContainer
                                dispatchProgramId={parseInt(id)}
                                transportationSubOrderItemId={transportationSubOrderItemId}
                                canBeEdited={false}
                                reloadModule={reloadModule}
                                changeStatus={true}
                                // dispatchProgramForm={dispatchProgramForm}
                                // setDispatchProgramForm={setDispatchProgramForm}
                            />
                        </>
                    ) : null}
                </>
            )}
        </>
    );
};

export default DispatchProgramEdit;
