import moment from 'moment';
import { BankAccount, BankAccountType } from './BankAccountType';
import { Carrier } from './Carrier';
import { Currency } from './Currency';
import { MovementType } from './MovementType';
import { Bank } from './Bank';
import { BusinessName } from './BusinessName';
import { BusinessEntity } from './BusinessEntity';
import { BankAccountReason } from './BankAccountReason';
import { TransferType } from './TransferType';

export interface CheckingAccount {
    id?: number;
    transfer_type?: TransferType;
    issue_date?: string;
    business_name?: BusinessName;
    business_name_id?: number;
    movement_type?: MovementType;
    business_entity?: BusinessEntity;
    bank_account_reason?: BankAccountReason;
    bank_account_reason_id?: number;
    bank_account?: BankAccount;
    bank_account_id?: number;
    account_number?: string;
    account_type?: BankAccountType;
    account_type_id?: number;
    bank?: Bank;
    bank_id?: number;
    amount?: number;
    currency?: Currency;
    currency_id?: number;
    is_active?: boolean;
    parent_checking_account?: CheckingAccount;
    parent_checking_account_id?: number| undefined;

    description?: string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;
    file?: File;
}

export interface CheckingAccountFilters {
    movement_type?: string | '';
    transfer_type?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
    issue_date_first?: string | '';
    issue_date_last?: string | '';
    carrier?: string | '';
}

export interface CheckingAccountDataForm {

    id?: number;
    transfer_type?: string;
    issue_date?: string;

    sender_business_name_id?: number;
    sender_business_name?: string;
    sender_business_entity?: string;
    sender_bank_account_reason_id?: number;
    sender_bank_account_id?: number;
    sender_bank?: string;
    sender_account_number?: string;
    sender_account_type_id?: number;
    sender_bank_id?: number;
    
    receiver_business_name_id?: number;
    receiver_business_name?: string;
    receiver_business_entity?: string;
    receiver_bank_account_reason_id?: number;
    receiver_bank_account_id?: number;
    receiver_account_number?: string;
    receiver_account_type_id?: number;
    receiver_bank_id?: number;
    receiver_bank?: string;
    

    created_at?: string;

    amount?: number;
    currency_id?: number;
    currency?: string;
    description?: string;
    file?: File;

}

export const defaultCheckingAccountDataForm: CheckingAccountDataForm  = {

    issue_date: moment().format('YYYY-MM-DD'),

    sender_bank_account_reason_id: 0,
    sender_bank_account_id: 0,
    sender_bank_id: 0,
    sender_account_type_id: 0,
    sender_account_number: '',
   
    receiver_bank_account_reason_id: 0,
    receiver_bank_account_id: 0,
    receiver_bank_id: 0,
    receiver_account_type_id: 0,
    receiver_account_number: '',

    currency_id: 0,
    description: ''
 
}


