import React from 'react';
import { IconProps } from '../types';

export const Places: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M16.0607 3.43934C16.6464 4.02513 16.6464 4.97487 16.0607 5.56066C15.4749 6.14645 14.5251 6.14645 13.9393 5.56066L13.5 5.12132V9.12496C12.9471 9.53059 12.4335 9.98664 11.966 10.4863C11.5068 10.1102 11.0167 9.7705 10.5 9.47161V5.12132L10.0607 5.56066C9.47487 6.14645 8.52513 6.14645 7.93934 5.56066C7.35355 4.97487 7.35355 4.02513 7.93934 3.43934L10.9393 0.43934C11.5251 -0.146447 12.4749 -0.146447 13.0607 0.43934L16.0607 3.43934Z"
                fill="#758CA3"
            />

            <path
                d="M20.5607 5.93934C19.9749 5.35355 19.0251 5.35355 18.4393 5.93934C17.8536 6.52513 17.8536 7.47487 18.4393 8.06066L18.9374 8.55876C14.1909 9.08702 10.5 13.1125 10.5 18V18.4013C9.6033 18.9201 9 19.8896 9 21C9 22.6569 10.3431 24 12 24C13.6569 24 15 22.6569 15 21C15 19.8896 14.3967 18.9201 13.5 18.4013V18C13.5 14.834 15.7636 12.1966 18.7607 11.618L18.4393 11.9393C17.8536 12.5251 17.8536 13.4749 18.4393 14.0607C19.0251 14.6464 19.9749 14.6464 20.5607 14.0607L23.5607 11.0607C24.1464 10.4749 24.1464 9.52513 23.5607 8.93934L20.5607 5.93934Z"
                fill="#758CA3"
            />

            <path
                d="M5.56066 6.93934C4.97487 6.35355 4.02513 6.35355 3.43934 6.93934L0.43934 9.93934C-0.146447 10.5251 -0.146447 11.4749 0.43934 12.0607L3.43934 15.0607C4.02513 15.6464 4.97487 15.6464 5.56066 15.0607C6.14645 14.4749 6.14645 13.5251 5.56066 12.9393L5.12245 12.5011C6.87068 12.5334 8.45019 13.2559 9.59998 14.4076C9.94241 13.4161 10.4226 12.4888 11.0178 11.6487C9.40816 10.3295 7.35747 9.52857 5.12057 9.50075L5.56066 9.06066C6.14645 8.47487 6.14645 7.52513 5.56066 6.93934Z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Places;
