import React, { useContext, useEffect, useState } from 'react';


import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';

import DefaultModal from '../../components/default/DefaultModal';
import DefaultToolBar from '../../components/default/DefaultToolBar';
import ButtonCreate from '../../components/buttons/ButtonCreate';

import { toast } from 'react-toastify';

import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import { AppContext } from '../../contexts/AppContext';

import { useHistory } from 'react-router-dom';

import CheckingAccountDataTable from './components/tables/CheckingAccountDataTable';
import CheckingAccountCreate from './components/CheckingAccountCreate';
import CheckingAccountEdit from './components/CheckingAccountEdit';
import useNavigationPage from '../../hooks/useNavigationPage';
import useCheckingAccountService from '../../app/services/hooks/useCheckingAccount';
import { CheckingAccountDataForm, CheckingAccountFilters } from '../../app/models/CheckingAccount';
import { TransferTypes } from '../../app/shared/enums';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Transferencias',
        url: '/checking-account',
        isActive: true
    }
];

const CheckingAccount = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const {
        fetchingGetCheckingAccounts,
        getCheckingAccounts,
        deleteCheckingAccount,
        activeCheckingAccount
    } = useCheckingAccountService();
    const [checkingAccounts, setCheckingAccounts] = useState<CheckingAccountDataForm[]>([]);
    const { navigationPage } = useNavigationPage();

    const [checkingAccountIdEdit, setCheckingAccountIdEdit] = useState<number>(-1);
    const [showingEdit, setShowingEdit] = useState(false);
    const [showingCreateTransfer, setShowingCreateTransfer] = useState(false);
    const [showingCreatePay, setShowingCreatePay] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [transferType, setTransferType] = useState<string>(TransferTypes.TRANSFER);
    const [checkingAccountFilter, setCheckingAccountFilter] = useState<CheckingAccountFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [checkingAccountFilter]);

    const getAllCheckingAccounts = () => {
        getCheckingAccounts(checkingAccountFilter, {
            onSuccess: (response: ServiceResponse) => {
                setCheckingAccounts(response.data.checking_accounts);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });

                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showEdit = (checkingAccountId: number) => {
        setShowingEdit(true);
        setCheckingAccountIdEdit(checkingAccountId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setCheckingAccountIdEdit(-1);
    };

    const showCreateTransfer = () => {
        setShowingCreateTransfer(true);
    };

    const showCreatePay = () => {
        setShowingCreatePay(true);
    };


    const hideCreateTransfer = () => {
        setShowingCreateTransfer(false);
    };

    const hideCreatePay= () => {
        setShowingCreatePay(false);
    };

    const reloadTable = () => {
        getAllCheckingAccounts();
    };

    const destroy = (checkingAccountId: number) => {
        const _text = 'Está a punto de eliminar la transferencia #' + checkingAccountId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando transferencia...');
                        deleteCheckingAccount(checkingAccountId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'La transferencia no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const active = (CheckingAccountId: number, active: boolean) => {
        activeCheckingAccount(CheckingAccountId, active, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                reloadTable();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Transferencias" breadcrumbs={breadcrumbs} />

            <DefaultCard>
                
                <div className="row">
                    <div className="col-12 mb-2">
                        <div className='row'>
                           <div className='col-auto'>
                       
                            <ButtonCreate
                                title="Nuevo transferencia"
                                callbackCreate={showCreateTransfer}
                            />
                        
                           </div>
                           <div className='col-auto'>
                           
                            <ButtonCreate
                                title="Nuevo pago"
                                callbackCreate={showCreatePay}
                            />
                        
                           </div>
                        </div>
                        
                    </div>
                    <div className="col-12">
                        <CheckingAccountDataTable
                            checkingAccounts={checkingAccounts}
                            edit={showEdit}
                            destroy={destroy}
                            active={active}
                            loading={fetchingGetCheckingAccounts}
                            filter={checkingAccountFilter}
                            setFilter={setCheckingAccountFilter}
                            totalRows={totalRows}
                            callbackSuccess={reloadTable}
                        />
                    </div>
                </div>
                
            </DefaultCard>

            {showingCreateTransfer ? (
                <DefaultModal
                    show={showingCreateTransfer}
                    handleClose={hideCreateTransfer}
                    title="Crear transferencia"
                    backdrop={true}
                    showFooter={false}
                >
                    <CheckingAccountCreate
                        transferType={TransferTypes.TRANSFER}
                        onSaved={() => {
                            reloadTable();
                            hideCreateTransfer();
                        }}
                        onCancel={hideCreateTransfer}
                        onError={hideCreateTransfer}
                    />
                </DefaultModal>
            ) : null}

            {showingCreatePay ? (
                <DefaultModal
                    show={showingCreatePay}
                    handleClose={hideCreatePay}
                    title="Crear pago"
                    backdrop={true}
                    showFooter={false}
                >
                    <CheckingAccountCreate
                        transferType={TransferTypes.PAY}
                        onSaved={() => {
                            reloadTable();
                            hideCreatePay();
                        }}
                        onCancel={hideCreatePay}
                        onError={hideCreatePay}
                    />
                </DefaultModal>
            ) : null}

            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title={`Editar ${transferType === TransferTypes.TRANSFER ? "transferencia": "pago"}`}
                    backdrop={true}
                    showFooter={false}
                >
                    <CheckingAccountEdit
                        checkingAccountId={checkingAccountIdEdit}
                        onSaved={() => {
                            reloadTable();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        onError={hideEdit}
                        transferType={transferType}
                        setTransferTypeEdit={setTransferType}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default CheckingAccount;
