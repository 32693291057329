import React from 'react';
import { IconProps } from '../types';

export const Hoppers: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M0 2C0 0.89543 0.89543 0 2 0H18C19.1046 0 20 0.89543 20 2V6.58579C20 7.11622 19.7893 7.62493 19.4142 8L13 14.4142V20C13 21.1046 12.1046 22 11 22H9C7.89543 22 7 21.1046 7 20V14.4142L0.58579 8C0.21071 7.62493 0 7.11622 0 6.58579V2z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Hoppers;
