import React from 'react';
import { IconProps } from '../types';

export const Purchases: React.FC<IconProps> = ({ size = 25, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M16.5538 4.99996L13.7786 2.10916C13.3962 1.71075 13.4091 1.07771 13.8075 0.69524C14.2059 0.31277 14.8389 0.32568 15.2214 0.7241L19.2214 4.89076C19.2547 4.92549 19.2851 4.96199 19.3124 4.99996H21.8196C21.9297 4.99996 22.0397 5.00906 22.1484 5.02717C23.2379 5.20876 23.9739 6.23922 23.7923 7.32876L22.2649 16.4932C22.0239 17.9397 20.7723 19 19.3058 19H4.69427C3.22776 19 1.97619 17.9397 1.73509 16.4932L0.207693 7.32876C0.189583 7.2201 0.180481 7.11012 0.180481 6.99996C0.180481 5.89539 1.07591 4.99996 2.18048 4.99996H4.68763C4.71497 4.96199 4.7453 4.92549 4.77863 4.89076L8.77863 0.7241C9.16111 0.32568 9.79414 0.31277 10.1926 0.69524C10.591 1.07771 10.6039 1.71075 10.2214 2.10916L7.44624 4.99996H16.5538zM12 9C12.5523 9 13 9.4477 13 10V14C13 14.5522 12.5523 15 12 15C11.4477 15 11 14.5522 11 14V10C11 9.4477 11.4477 9 12 9zM7.00002 9C7.55231 9 8.00002 9.4477 8.00002 10V14C8.00002 14.5522 7.55231 15 7.00002 15C6.44774 15 6.00002 14.5522 6.00002 14V10C6.00002 9.4477 6.44774 9 7.00002 9zM17 9C17.5523 9 18 9.4477 18 10V14C18 14.5522 17.5523 15 17 15C16.4477 15 16 14.5522 16 14V10C16 9.4477 16.4477 9 17 9z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default Purchases;