import React from 'react';
import { IconProps } from '../types';

export const PaymentStatus: React.FC<IconProps> = ({ size = 20, ...rest }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 25 25"
            fill="currentColor"
            {...rest}
        >
            <path
                d="M22.0208 9.0001C23.1158 9.0113 24 9.9024 24 11V13C24 14.0976 23.1158 14.9887 22.0208 14.9999V17C22.0208 18.6569 20.6777 20 19.0208 20H3.01666C1.36224 20 0.0201073 18.6607 0.016662 17.0058C0.00948531 12.697 0.00905352 12.4378 0.00499359 10H0V3C0 1.34315 1.34315 0 3 0H16.025C17.6818 0 19.025 1.34315 19.0249 3.01249L19.0126 4H19.0208C20.6777 4 22.0208 5.34315 22.0208 7V9.0001zM2 3C2 3.55228 2.44772 4 3 4H17.0125L17.025 3C17.025 2.44772 16.5773 2 16.025 2H3C2.44772 2 2 2.44772 2 3zM19 11V13H22V11H19z"
                fill="#758CA3"
            />
        </svg>
    );
};

export default PaymentStatus;