import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import CheckingAccountForm from './forms/CheckingAccountForm';
import { Currency } from '../../../app/models/Currency';
import { Bank } from '../../../app/models/Bank';
import { CheckingAccountDataForm } from '../../../app/models/CheckingAccount';
import { BankAccount, BankAccountType } from '../../../app/models/BankAccountType';
import { BusinessName } from '../../../app/models/BusinessName';
import { BusinessEntity } from '../../../app/models/BusinessEntity';
import { BankAccountReason } from '../../../app/models/BankAccountReason';
import { BusinessNameBankAccount } from '../../../app/models/BusinessNameBankAccount';


interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    checkingAccount: CheckingAccountDataForm;
    setCheckingAccount: (checkingAccount: CheckingAccountDataForm) => void;
    transferType: string;
    senderBusinessNames: BusinessName[];
    senderBusinessEntities: BusinessEntity[];
    senderBankAccountReasons: BankAccountReason[];
    senderBankAccounts: BusinessNameBankAccount[];
    senderBanks: Bank[];
    senderBankAccountTypes: BankAccountType[];
    receiverBusinessNames: BusinessName[];
    receiverBusinessEntities: BusinessEntity[];
    receiverBankAccountReasons: BankAccountReason[];
    receiverBankAccounts: BusinessNameBankAccount[];
    receiverBanks: Bank[];
    receiverBankAccountTypes: BankAccountType[];
    currencies: Currency[];
    errorFields?: any;
    loadingSenderBusinessName: boolean;
    loadingSenderBankAccount: boolean;
    loadingReceiverBusinessName: boolean;
    loadingReceiverBankAccount: boolean;
}

const CheckingAccountFormContainer = ({
    fetching,
    action,
    cancel,
    checkingAccount,
    setCheckingAccount,
    transferType,
    senderBusinessNames,
    senderBusinessEntities,
    receiverBusinessNames,
    receiverBusinessEntities,
    senderBankAccountReasons,
    senderBankAccounts,
    senderBanks,
    receiverBanks,
    senderBankAccountTypes,
    receiverBankAccountReasons,
    receiverBankAccounts,
    receiverBankAccountTypes,
    currencies,
    errorFields = null,
    loadingSenderBusinessName,
    loadingSenderBankAccount,
    loadingReceiverBusinessName,
    loadingReceiverBankAccount
 }: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <CheckingAccountForm
                        checkingAccountDataForm={checkingAccount}
                        setCheckingAccountDataForm={setCheckingAccount}
                        transferType={transferType}
                        senderBusinessNames={senderBusinessNames}
                        senderBusinessEntities={senderBusinessEntities}
                        senderBankAccountReasons={senderBankAccountReasons}
                        senderBankAccounts={senderBankAccounts}
                        senderBanks={senderBanks}
                        senderBankAccountTypes={senderBankAccountTypes}
                        currencies={currencies}
                        receiverBusinessNames={receiverBusinessNames}
                        receiverBusinessEntities={receiverBusinessEntities}
                        receiverBankAccountReasons={receiverBankAccountReasons}
                        receiverBankAccounts={receiverBankAccounts}
                        receiverBanks={receiverBanks}
                        receiverBankAccountTypes={receiverBankAccountTypes}
                        errorFields={errorFields}
                        loadingSenderBusinessName={loadingSenderBusinessName}
                        loadingSenderBankAccount={loadingSenderBankAccount}
                        loadingReceiverBusinessName={loadingReceiverBusinessName}
                        loadingReceiverBankAccount={loadingReceiverBankAccount}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default CheckingAccountFormContainer;
