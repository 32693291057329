import { useState } from 'react';

const useHandleErrorFields = () => {
    const [errors, setErrors] = useState<any>();
    const [errorsArray, setErrorsArray] = useState<Record<string, any>>({});

    const getErrorMessage = (name: string, type: string) => {
        const error = errorsArray[name];
        if (error && error[type]) {
            return error[type][0];
        }
        return '';
    };

    const hasError = (name: string, type: string) => {
        const error = errorsArray[name];
        if (error && error[type]) {
            return 'is-invalid';
        }
        return '';
    };

    const clearError = (name: string, type: string) => {
        const newErrors = { ...errorsArray };
        if (newErrors[name] && newErrors[name][type]) {
            delete newErrors[name][type];
            if (Object.keys(newErrors[name]).length === 0) {
                delete newErrors[name];
            }
        }
        setErrorsArray(newErrors);
    };

    const fieldErrorMessage = (name: string) => {
        if (errors) {
            if (errors[name]) {
                return errors[name][0];
            }
        }
        return '';
    };

    const fieldHasError = (name: string) => {
        if (errors) {
            if (errors[name]) {
                return 'is-invalid';
            }
        }
        return '';
    };

    const onFocusRemove = (name: string) => {
        setErrors({ ...errors, [name]: undefined });
    };

    return {
        fieldErrorMessage,
        fieldHasError,
        onFocusRemove,
        setErrors,
        errors,
        errorsArray,
        setErrorsArray,
        getErrorMessage,
        hasError,
        clearError
    };
};

export default useHandleErrorFields;
