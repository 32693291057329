import React, { useEffect } from 'react';
import { PaymentStatusDataForm } from '../../../app/models/PaymentStatus';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import { momentParseDate } from '../../../helpers';

type Props = {
    paymentStatus: PaymentStatusDataForm;
    setPaymentStatus: (PaymentStatus: PaymentStatusDataForm) => void;
    errorFields?: any;
};

const PaymentStatusForm = ({ paymentStatus, setPaymentStatus, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setPaymentStatus({ ...paymentStatus, [name]: value });
    };

    return (
        <>

            <div className="form-group mt-2">
                <label htmlFor="client">Cliente</label>
                <input
                    type="text"
                    className="form-control"
                    id="client"
                    name="client"
                    value={paymentStatus.client}
                    onChange={handleChange}
                    disabled

                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="contactEmail">Email de Contacto</label>
                <input
                    type="email"
                    className="form-control"
                    id="contact_email"
                    name="contact_email"
                    value={paymentStatus.contact_email}
                    onChange={handleChange}
                    disabled

                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="contactPhone">Teléfono de Contacto</label>
                <input
                    className="form-control"
                    name="contact_phone"
                    value={paymentStatus.contact_phone}
                    onChange={handleChange}
                    disabled

                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="paymentConditionId">Condición de Pago</label>
                <input
                    className="form-control"
                    name="payment_condition"
                    value={paymentStatus.payment_condition}
                    onChange={handleChange}
                    disabled
                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="createdAt">Fecha de Emisión</label>
                <input
                    type="text"
                    className="form-control"
                    id="created_at"
                    name="created_at"
                    value={momentParseDate(paymentStatus.issue_date)}
                    onChange={handleChange}
                    disabled
                />
            </div>
        </>
    );
};

export default PaymentStatusForm;
