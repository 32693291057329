import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { useState } from 'react';
import { EndPoints } from '../EndPoints';
import { ServiceManifestDataForm, ServiceManifestFilters } from '../../models/ServiceManifest';

const useServiceManifestService = () => {
    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetServiceManifests, setFetchingGetServiceManifests] = useState(false);
    const [fetchingShowServiceManifest, setFetchingShowServiceManifest] = useState(false);
    const [fetchingCreateServiceManifest, setFetchingCreateServiceManifest] = useState(false);
    const [fetchingStoreServiceManifest, setFetchingStoreServiceManifest] = useState(false);
    const [fetchingEditServiceManifest, setFetchingEditServiceManifest] = useState(false);
    const [fetchingUpdateServiceManifest, setFetchingUpdateServiceManifest] = useState(false);
    const [fetchingDeleteServiceManifest, setFetchingDeleteServiceManifest] = useState(false);
    const [fetchingActiveServiceManifest, setFetchingActiveServiceManifest] = useState(false);
    const [fetchingChangeStatusServiceManifest, setFetchingChangeStatusServiceManifest] =
        useState(false);
    const [fetchingHasFinishedStatuses, setFetchingHasFinishedStatuses] = useState(false);
    const [fetchingStoreServiceManifestOnDispatchProgram, setFetchingStoreServiceManifestOnDispatchProgram] = useState(false);
    const [fetchingDeleteServiceManifestByDispatchProgram, setFetchingDeleteServiceManifestByDispatchProgram] = useState(false);
    const [fetchingGoServiceManifest, setGoServiceManifestes] = useState(false);

    const getServiceManifests = (
        serviceManifestFilters: ServiceManifestFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(serviceManifestFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.SERVICE_MANIFEST.GET_SERVICE_MANIFESTS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetServiceManifests
        });
    };

    const showServiceManifest = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.SHOW_SERVICE_MANIFEST.replace(':id', id.toString()),
            setFetching: setFetchingShowServiceManifest
        });
    };

    const createServiceManifest = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.CREATE_SERVICE_MANIFEST,
            setFetching: setFetchingCreateServiceManifest
        });
    };

    const storeServiceManifest = (
        serviceManifest: ServiceManifestDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.STORE_SERVICE_MANIFEST,
            body: serviceManifest,
            setFetching: setFetchingStoreServiceManifest
        });
    };

    const editServiceManifest = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.EDIT_SERVICE_MANIFEST.replace(':id', id.toString()),
            setFetching: setFetchingEditServiceManifest
        });
    };

    const updateServiceManifest = (
        id: number,
        serviceManifest: ServiceManifestDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.UPDATE_SERVICE_MANIFEST.replace(':id', id.toString()),
            body: serviceManifest,
            setFetching: setFetchingUpdateServiceManifest
        });
    };

    const deleteServiceManifest = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.DELETE_SERVICE_MANIFEST.replace(':id', id.toString()),
            setFetching: setFetchingDeleteServiceManifest
        });
    };

    const deleteServiceManifestByDispatchProgram = (service_manifest: ServiceManifestDataForm, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.DELETE_SERVICE_MANIFEST_BY_DISPATCH_PROGRAM,
            body: service_manifest,
            setFetching: setFetchingDeleteServiceManifestByDispatchProgram
        });
    };

    const hasAllFinishedStatuses = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.HAS_ALL_FINISHED_STATUSES.replace(
                ':id',
                id.toString()
            ).replace(':service_manifest_status', status),
            setFetching: setFetchingHasFinishedStatuses
        });
    };

    const changeStatusServiceManifest = (
        id: number,
        status: string,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.CHANGE_STATUS_SERVICE_MANIFEST.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusServiceManifest
        });
    };

    const storeServiceManifestOnDispatchProgram = (
        serviceManifest: ServiceManifestDataForm,
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.STORE_SERVICE_MANIFEST_ON_DISPATCH_PROGRAM,
            body: serviceManifest,
            setFetching: setFetchingStoreServiceManifestOnDispatchProgram
        });
    };

    const goServiceManifest = (provider_id: number, dispatch_program_id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.SERVICE_MANIFEST.GO_SERVICE_MANIFEST.replace(
                ':provider_id', provider_id.toString()
            ).replace(':dispatch_program_id', dispatch_program_id.toString()
        ),
            setFetching: setGoServiceManifestes
        });
    };

    return {
        fetchingGetServiceManifests,
        fetchingShowServiceManifest,
        fetchingCreateServiceManifest,
        fetchingStoreServiceManifest,
        fetchingEditServiceManifest,
        fetchingUpdateServiceManifest,
        fetchingDeleteServiceManifest,
        fetchingActiveServiceManifest,
        fetchingHasFinishedStatuses,
        fetchingChangeStatusServiceManifest,
        fetchingStoreServiceManifestOnDispatchProgram,
        fetchingDeleteServiceManifestByDispatchProgram,
        fetchingGoServiceManifest,
        getServiceManifests,
        showServiceManifest,
        createServiceManifest,
        storeServiceManifest,
        editServiceManifest,
        updateServiceManifest,
        deleteServiceManifest,
        hasAllFinishedStatuses,
        changeStatusServiceManifest,
        storeServiceManifestOnDispatchProgram,
        deleteServiceManifestByDispatchProgram,
        goServiceManifest
    };
};

export default useServiceManifestService;
