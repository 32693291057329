import React, { useEffect, useState } from 'react';

import { defaultHopper, Hopper } from '../../../app/models/Hopper';
import useHopperService from '../../../app/services/hooks/useHopperService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import HopperFormContainer from './HopperFormContainer';
import LazyLoading from '../../../components/LazyLoading';
import { Truck } from '../../../app/models/Truck';
import { Carrier } from '../../../app/models/Carrier';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message: string) => void;
}

const HopperCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { fetchingStoreHopper, storeHopper, createHopper, fetchingCreateHopper } =
        useHopperService();
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    const [hopper, setHopper] = useState<Hopper>(defaultHopper);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        createHopper({
            onSuccess: (response: ServiceResponse) => {
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
            }
        });
    }, []);

    const store = () => {
        storeHopper(hopper, {
            onSuccess: (response: ServiceResponse) => {
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (onError) onError(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateHopper ? (
        <LazyLoading height={300} />
    ) : (
        <HopperFormContainer
            fetching={fetchingStoreHopper}
            action={store}
            cancel={cancel}
            hopper={hopper}
            setHopper={setHopper}
            errorFields={errorFields}
            carriers={carriers}
            trucks={trucks}
        />
    );
};

export default HopperCreate;
